export const graphUrls = {
  blankImageUrl: "https://i.ibb.co/K29Rbb2/while-image.png",
  cyklusPositiveUrl: "https://i.ibb.co/pvmfWnN/cyklus-positive.png",
  cyklusNegativeUrl: "https://i.ibb.co/XLh8tyR/negative-cyklus.png",
  dheaUrl: "https://i.ibb.co/q1VDjzs/dhea.png",
  kortisolUrl: "https://i.ibb.co/YfgXr1w/kortisol.png",
  ostriolUrl: "https://i.ibb.co/nzNwY8f/ostriol.png",
  progesteronBasedOnPositiveCyklusUrl:
    "https://i.ibb.co/T18PSNH/progesteron-based-on-positive-cyklus.png",
  progesteronBasedOnNegativeCyklusUrl:
    "https://i.ibb.co/nC09DG3/progesteron-based-on-negative-cyklus.png",
  testosteronBasedOnMaleUrl:
    "https://i.ibb.co/X86bC6b/testosteron-based-on-male.png",
  testosteronBasedOnOthersUrl: "https://i.ibb.co/55Gcnqv/testosteron-other.png",
  recommendationHeading: "https://i.ibb.co/P5NM1Dc/Anbefaling.png",
  cyklusNormalHeading: "https://i.ibb.co/vH6VryT/cyklus-normal-heading.png",
  cortisolHeading: "https://i.ibb.co/47bWsx5/cortisol-heading.png",
  dheaHeading: "https://i.ibb.co/xMgGVwF/dhea-heading.png",
  ostriolHeading: "https://i.ibb.co/pjfD0jx/ostriol-heading.png",
  progesteronHeading: "https://i.ibb.co/FD6LwTY/progesteron-heading.png",
  testestorenHeadingMale:
    "https://i.ibb.co/XLg3J5s/testestoren-heading-male.png",
  testosteronHeadingFemale:
    "https://i.ibb.co/jVd8NPX/testosteron-heading-female.png",
  ostriolKnowledgeHeadingUrl: "https://i.ibb.co/PjfRmh0/Viden-om-ostriol.png",
  cyklusKnowledgeHeadingUrl: "https://i.ibb.co/V374pDr/Viden-om-stradiol.png",
  dheaKnowledgeHeadingUrl: "https://i.ibb.co/pxmwdht/Viden-om-DHEA.png",
  testosteronKnowledgeHeadingUrl:
    "https://i.ibb.co/XLV2HWP/Viden-om-testosteron.png",
  progesteronKnowledgeHeadingUrl:
    "https://i.ibb.co/5k99bwC/Viden-om-progesteron.png",
  cortisolKnowledgeHeadingUrl: "https://i.ibb.co/yspYM2N/Viden-om-Cortisol.png",
  hormoneKnowledgeHeadingUrl: "https://i.ibb.co/J7c8w1N/intro-om-hormoner.png",
  ostriolmangelKnowledgeHeadingUrl:
    "https://i.ibb.co/C18p6Hk/Viden-om-striol.png",
  ostrogenerKnowledgeHeadingUrl:
    "https://i.ibb.co/grqcqBv/Viden-om-strogener.png",
};
// cyklus
export const knowledgeOfOstradiol = `Østradiol er det der i folkemunde kaldes ”østrogen”, men det er egentlig en gruppe.
Østradiol er det østrogenhormon, som er kraftigst virkende. Hormonet er ansvarligt for de kvindelige kønskarakteristika som bryst og runde former, men har også betydning for huden, skelettet og nervesystemet. Østradiol stimulerer også til vækst af celler og er helt afgørende for, at kvinder kan udvikle et foster i livmoderen.
Det er meget afgørende, at østradiol er i de rette mængder i kroppen, da for meget kan give negative symptomer som hedeture, væskeophobning, øgede fedtdepoter, cyster samt uhæmmet celledeling, der i værste fald kan være en af faktorerne ved brystkræft.
Både mænd og kvinder producerer østradiol, men i forskellige mængder. Kvinders niveauer er også forskellige igennem livet: Højst i de år, hvor hun er fertil og mindre ved overgangsalderen. Ofte er mængden af østradiol for kvinden i overgangsalderen dog ikke for lille!
Hos yngre kvinder i produceres østradiol fra puberteten og indtil overgangsalderen primært i æggestokkene. 
Hos kvinder efter overgangsalderen produceres det i binyrerne, fedtvævet, huden og hjernen. 
Hos mænd produceres det primært i testiklerne, men også i binyrerne, fedtvævet, huden og hjernen.
Mange mænd producerer mere østradiol med alderen ofte pga. øgede fedtdepoter, som skyldes, at testosteron falder.
Har man store mængder af fedtvæv uanset alder og køn, vil det også bidrage til en øget mængde af østradiol.
Ofte mangler kvinder uanset alder ikke østradiol, selvom det er tesen for kvinder i overgangsalderen.
`;
export const knowledgeOfOstriol = `Lige som både mænd og kvinder har østradiol, så har begge køn også østriol.
Østriol tilhører også gruppen af østrogener, men er det svagest virkende østrogen og kan fx ikke celledele, danne fedt eller danne cyster. Når moderhormonet østradiol nedbrydes i leveren, æggestokkene og binyrerne bliver det til østriol.
Østriol ”nærer”, opbygger og er helt afgørende for optimale slimhinder i hele kroppen.
Vi har slimhinder mange steder i kroppen: i øjne, næse, mund, tarm, i blære og hos kvinder i skeden. 
`;
export const knowledgeOfOstriolmangel = `Mangler man østriol, kan det typisk give problemer med tørre slimhinder i et eller flere organer som øjne, næse, mund, blære eller skede. Dog kan lunger, tarm og hud også påvirkes. 
Blæreproblemer som dryp ved hop og nys, hyppige wc-besøg samt behov for at tisse om natten hænger ofte typisk sammen med østriolmangel hos kvinder.
Specielt mænd kan opleve ”slap” stråle eller behov for at tisse flere gange hver nat ved mangel på østriol.
Korttidshukommelsen kan påvirkes hos begge køn ved mangel. Det betyder, at man ikke kan huske, hvad man ville sige, navne på almindelige ting eller hvad man lavede i går.
`;
export const knowledgeOfFemaleTestosteron = `Testosteron er det kraftigste maskuline hormon, som mænd og kvinder har i vidt forskellige mængder. Mænd kan, når de ”topper” i mængden af testosteron, have 4-5 gange så meget som kvinder på samme alder. 
Hormonet falder normalvis fra slutningen af 20’erne, dog er faldet større hos mænd end kvinder, da udgangspunktet er vidt forskelligt.
Testosteron er ansvarligt for de mandlige kønskarakteristika og har hos det enkelte individ, afgørende betydning for muskelmasse, behåring og stemmens toneleje. 
Hormonet har derudover funktioner som styrke, udholdenhed, energi, libido, omsætning af fedtstofskiftet samt psyke og temperament. Huden samt muskler og knoglernes kollagenproduktion er også afhængig af testosteron.
Testosteron i rette mængder har stor betydning for vægten og fedtomsætningen. 
Hormonet er modspiller til det kvindelige østradiol og stresshormonet cortisol.
Produktionen forgår primært i æggestokkene hos kvinder og testiklerne hos mænd og i mindre mængder i binyrebarken.
Hormonet dannes ud fra progesteron og kan omdannes til DHEA samt østradiol, hvis det er i for store mængder.
`;
export const knowledgeOfMaleTestosteron = `Produktionen af testosteron foregår primært i æggestokkene hos kvinder og testiklerne hos mænd og i mindre mængder i binyrebarken.
Testosteron er det kraftigste maskuline hormon, som mænd og kvinder har i vidt forskellige mængder. Mænd kan, når de ”topper” i mængden af testosteron, have 4-5 gange så meget som kvinder på samme alder. 
Hormonet falder normalvis fra slutningen af 20’erne, dog er faldet større hos mænd end kvinder, da udgangspunktet er vidt forskelligt.
Testosteron er ansvarligt for de mandlige kønskarakteristika, og har hos det enkelte individ, afgørende betydning for muskelmasse, behåring og stemmens toneleje. 
Hormonet har derudover funktioner som styrke, udholdenhed, energi, libido, omsætning af fedtstofskiftet samt psyke og temperament. Huden samt muskler og knoglernes kollagenproduktion er også afhængig af testosteron.
Testosteron i rette mængder har stor betydning for vægten og fedtomsætningen. 
Hormonet er modspiller til det kvindelige østradiol og stresshormonet cortisol.
Hormonet dannes ud fra progesteron og kan omdannes til DHEA samt østradiol, hvis det er i for store mængder.
`;
export const knowledgeOfDHEA = `DHEA er et af de to mandlige kønshormoner, de såkaldte androgener. DHEA har dog en lang svagere maskulin virkning end testosteron. Kvinder som mænd producerer DHEA.
DHEA produceres primært i binyrebarken ud fra et andet hormon, nemlig progesteron. Det produceres desuden i æggestokkene hos kvinder og i testiklerne hos mænd. 
DHEA kan omdannes videre til testosteron og kvindelige kønshormoner.
Det er et vigtigt hormon for immunforsvaret og nervesystemet. Det har også en rolle for vores aldring, da det er vores foryngelseshormon. Det er forbundet med god energi at have nok DHEA.
DHEA i rette mængder er afgørende for et godt immunforsvar, fedtomsætningen, mindre risiko for overvægt, et sundere hjertekar-system samt forebyggelse af hjertekarsygdom. Det har også en betydning som modspiller til kortisol og stress.`;
export const knowledgeOfCortisol = `Kortisol produceres i binyrebarken og har mange funktioner. Kortisol er livsnødvendigt, og balancen helt afgørende. Kortisol produceres ud fra hormonet progesteron.
Det er vores langtidskamp-flugt-stress hormon, som er helt afgørende for, at vi kan overleve og handle os ud af farlige situationer. Mangler vi kortisol, kan vi risikere at ”fryse”/stivne og ikke overskue krævende situationer. Det kan være livsfarligt, men oftest er det mere til stor gene i en hverdag, hvis man oplever at gå i stå, hvor der er brug for det modsatte.
Det påvirker omsætningen af kulhydrater, fedt og proteiner, når der er brug for det, så der kan frigives energi. Det påvirker stofskiftet og blodsukkeret.
Kortisol svinger normalt gennem døgnet og vil være højst om morgenen.
For højt kortisol tyder på stress eller inflammation i kroppen, mens for lavt kortisol kan skyldes binyretræthed, en ikke optimal funktion af binyrebarken eller stofskifteproblematik. 
Er man udsat for længerevarende stress, er restitution vigtig at prioritere for, at niveauet kan afbalanceres.
Mange tror, de har for meget kortisol; fordi de føler sig stresset og presset. Ofte handler det faktisk om manglende afbalancering af kortisol eller direkte mangel på hormonet. Det virker voldsom belastende og direkte stressende, at man ikke kan aktivere det kortisol, der måske er brug for.
Man skal hverken have for meget eller for lidt kortisol, men være albalanceret inden for et interval.
`;
export const knowledgeOfprogesteron = `Progesteron produceres i forskellige væv alt efter alder og køn. Begge køn producerer mindre mængder i binyrebarken, 
                                        hjernen og nervesystemet. Men derudover er der store forskelle.`;
export const introOnHormoner = `Alle mennesker har alle hormoner uanset køn og alder. Mængderne er dog forskellige.
                                Der findes et hav af hormonsystemer i kroppen!
                                Hormoner er kroppens budbringere, der sender beskeder mellem vævene og nerverne om hvad, der skal ske.  
                                Kønshormonerne, som er testet i denne test, har kønsspecifikke funktioner, men faktisk også mange andre og livsnødvendige funktioner. 
                                Det bliver du klogere på i teksten herunder. Lige som kvinder har testosteron, så har mænd østrogener! Ja, begge køn har brug for alle hormoner, da de har forskellige funktioner.
                                I testen her er der testet for 5 kønshormoner og kortisol. Årsagen til, at stresshormonet kortisol er testet, er, at det har et tæt samspil med kønshormonerne.`;
export const knowledgeOfOstrogener = `Gruppen af østrogener består af 3 østrogener, hvoraf 2 af dem er med i testen her; nemlig østradiol og østriol. 
Det sidste østrogen i gruppen hedder østron og er et lagerhormon uden egentlig aktivitet og funktion.
Østrogenerne spiller en vigtig rolle ved at stimulere væksten af reproduktivt væv, opretholde sunde knogler, øge niveauerne af neurotransmittere i hjernen og hjælpe med at holde hjertekar-systemet sundt. Østrogenerne har også betydning for de kognitive funktioner, for humør og energiniveau. 
`;
export const cyklusNormalResponse = `Dit testsvar ligger inden for den optimale værdi, som bør ligge under 3,0 pg/ml.
                                    Du er kvinde uden cyklus og bør derfor ikke have et for højt østradiolniveau pga. den celledelende virkning.
                                    Det er godt og optimalt for din sundhed, at du ligger inden for den optimale grænse.
                                    Hvis du oplever symptomer, som normalvis ville være tegn på for højt østradiol, 
                                    kan det skyldes ubalance i andre hormoner og ofte mangel på progesteron.
                                    Så selv ved et optimalt østradiolniveau kan du opleve østrogendominans, 
                                    hvis der er mangel på de hormoner, der skal afbalancere det.`;
export const cyklusOverResponse = `Dit svar ligger for højt i forhold til den optimale værdi, som bør ligge under 3,0 pg/ml. 
                                    Du er kvinde uden cyklus og bør derfor ikke have et østradiolniveau, der er over 3,0 pg/ml pga. den celledelende virkning, som hormonet har. 
                                    Hvis du samtidig oplever symptomer på, at dit østradiol er for højt, er det helt tydeligt, at du har østrogendominans.`;
export const cyklusPositiveOverResponse = `Dit svar ligger for højt i forhold til den optimale værdi, som bør ligge under 4,0 pg/ml. 
                                            Du er kvinde med cyklus, men bør alligevel ikke have for høje østradiol niveauer pga. de problematikker, det kan medføre. 
                                            Samtidig har du symptomer på, at dit østradiol er for højt. Det kan være i form af væskeophobning, oppustethed, PMS, vægtøgning, hovedpine, mm.`;
export const cyklusPositiveNormalResponse = `Dit svar på østradiol er inden for den optimale værdi, som er under 4,0 pg/ml. 
                                            Du er kvinde med cyklus, og derfor kan dit niveau af østradiol svinge igennem din cyklus.
                                            Det er afgørende for, at du ikke oplever de problematikker et for højt østradiol kan medføre periodevis. 
                                            Har du symptomer til trods for, at dit østradiol ligger inden for optimalværdien,
                                            så kan det skyldes mangel på andre hormoner; primært progesteron. (Gennemgås senere i testsvaret).`;
export const ostriolOverResponse = `Dit svar ligger over minimum for den optimale værdi, som bør ligge over 10 pg/ml.`;
export const ostriolNormalResponse = `Dit svar ligger for lavt i forhold til den optimale værdi, som bør ligge over 10 pg/ml.`;
export const progesteronBasedOnNegativeCyklusOverResponse = `Dit testsvar viser, at dit progesteron niveau er optimalt, da det ligger over 250 pg/ml.`;
export const progesteronBasedOnNegativeCyklusNormalResponse = `Dit testsvar ligger for lavt i forhold til den optimale værdi, som er over 250 pg/ml.`;
export const progesteronBasedOn_positiveCyklusOverResponse = `Dit testsvar er optimalt, da det ligger over 500 pg/ml.`;
export const progesteronBasedOn_positiveCyklusNormalResponse = `Dit testsvar ligger for lavt i forhold til den optimale værdi, som ligger over 500 pg/ml.`;
export const testosteronOverResponse = `Dit testsvar er optimalt, da det ligger over 20 pg/ml.`;
export const testosteronNormalResponse = `Dit testsvar ligger for lavt i forhold til den optimale værdi, som bør ligge over 20 pg/ml.`;
export const testesteronOverResponse = `Dit testsvar ligger over minimumsværdien, som er over 100 pg/ml. `;
export const testesteronNormalResponse = `Dit svar ligger for lavt i forhold til den optimale værdi, som bør ligge over 100 pg/ml.`;
export const dheaOverResponse = `Dit svar ligger inden for optimal værdi, som bør ligge over 300 pg/ml.`;
export const dheaNormalResponse = `Dit svar ligger for lavt i forhold til den optimale værdi, som bør ligge over 300 pg/ml.`;
export const cortisolOverResponse = `Dit testsvar er ikke optimalt, da cortisol niveauet er over 4200 pg/ml. 
                                    Det kan være, du oplever symptomer som uro, søvnbesvær, opvågnen, humørsvingninger, kort lunte, en følelse af at have for mange bolde i luften, hjertebanken mm.`;
export const cortisolNormalResponse = `Dit testsvar ligger optimalt, da kortisol ligger inden for intervallet 1400-4200 pg/ml. `;
export const cortisolUnderResponse = `Dit testsvar er ikke optimalt, da kortisol ligger under 1400 pg/ml. 
                                    Symptomer på for lavt cortisol kan være udkørthed, lav stresstærskel, en følelse af at være kronisk stresset og ikke kunne klare det mindste pres.
                                    kortisol kan ligge for lavt pga. mangel på progesteron (som er moderhormonet); det er dog sjældent årsag alene, da kroppen altid vil prioritere at producere nok kortisol. 
                                    Har man været udsat for massiv stress over lang tid og ikke haft mulighed for restitution, kan man have udkørte binyrer, som kører i slowmotion. Det er kendt som fænomenet binyretræthed. Andre årsager kan være stofskifteproblematikker eller lignende.`;

export const cyklusNormalRecommendation = `Det er altid en god ide generelt at udrense for fremmedøstrogener/hormonforstyrrende stoffer, da disse 
hormonlignende stoffer "opfører" sig som østrogener. De er ikke målt i hormontesten. Du kan også 
reducere disse hormonforstyrrende stoffer vha. en hormondetoxkur. Du kan læse mere om den på dette link: 
<a href="https://hormonklinikken.dk/products/detox">https://hormonklinikken.dk/products/detox</a>
Samtidig er det vigtigt at forebygge ny belastning. Det kan du lære mere om i denne <a href="https://hormonklinikken.dk/products/hormondetox-ebog">E-guide</a> Hormondetox`;
export const cyklusOverRecommendation = `Det er altid en god ide generelt at udrense for fremmedøstrogener/hormonforstyrrende stoffer, da disse 
hormonlignende stoffer "opfører" sig som østrogener. De er ikke målt i hormontesten. Du kan også 
reducere disse hormonforstyrrende stoffer vha. en hormondetoxkur. Du kan læse mere om den her: 
<a href="https://hormonklinikken.dk/products/detox">https://hormonklinikken.dk/products/detox</a>
Samtidig er det vigtigt at forebygge ny belastning. Det kan du lære mere om hormondetox og 
forebyggelse i denne E-guiden her:<a href="https://hormonklinikken.dk/products/hormondetox-ebog">https://hormonklinikken.dk/products/hormondetox-ebog</a>`;
export const cyklusPositiveOverRecommendation = `Det er altid en god ide at udrense for fremmedøstrogener/hormonforstyrrende stoffer, da de ”opfører” sig som østradiol. Dette måles ikke i hormontesten.
                                                Du kan også reducere østradiol vha. en hormondetox kur. Du kan læse mere om den på dette link <a href="https://hormonklinikken.dk/products/detox">https://hormonklinikken.dk/products/detox</a>. Samtidig er det vigtigt at forebygge ny belastning. Det kan du lære mere i denne <a href="https://hormonklinikken.dk/products/hormondetox-ebog">E-guide</a> Hormondetox.
                                                Får du østradiol tilskud på den ene eller anden måde, kan det anbefales, at reducere eller trappe ud af dette evt. i samråd med din læge. Det kan være som plaster, p-piller, mini-piller.
                                                Det samme vil gøre sig gældende for tilskud af rødkløverprodukter eller Femarelle.

                                                Det kunne være optimalt for hormonbalancen at skifte tilskud ud med de hormoner, du mangler eller som udgangspunkt lægge dem på behandlingen. 
                                                Det er er uanset, om du får hormonerne fra lægen eller bruger andre tilskud. 
                                                Får du p-piller eller mini-piller som præventionsforebyggende, kan det anbefales at bruge app’en Clue dom, som kan tracke ægløsninger. 
                                                Er det pga. kraftige blødninger, du får ovenstående hormoner, så kan det skyldes ubalancen i dine hormoner.
                                                Ofte vil en behandlingsmulighed, hvor man giver kroppen, hvad den mangler af hormoner, kunne afhjælpe balancen og dermed symptomerne.`;
export const cyklusPositiveNormalRecommendation = `Det er altid en god ide at udrense for fremmedøstrogener/hormonforstyrrende stoffer, da de ”opfører” sig som østradiol. 
                                                    Dette måles ikke i hormontesten.
                                                    Du kan vha. hormondetox kur støtte din hormonbalance. Du kan læse mere om den på dette link: <a href="https://hormonklinikken.dk/products/detox">https://hormonklinikken.dk/products/detox</a>. Samtidig er det vigtigt at forebygge ny belastning. Det kan du læse mere om i denne <a href="https://hormonklinikken.dk/products/hormondetox-ebog">E-guide</a> Hormondetox.
                                                    Får du østradiol tilskud på den ene eller anden måde, tyder det på, at du få de mængder, din krop har brug for.
                                                    Det kunne være optimalt for hormonbalancen at skifte tilskud ud med de hormoner, du mangler. Eller som udgangspunkt 
                                                    lægge dem på behandlingen. Det er er uanset, om du får hormonerne fra lægen eller bruger andre tilskud. 
                                                    Får du p-piller eller mini-piller som præventionsforebyggende, kan det anbefales at bruge app’en Clue dom, 
                                                    som kan tracke ægløsninger. Er det pga. kraftige blødninger, du får ovenstående hormoner, 
                                                    så kan det skyldes ubalancen i dine hormoner.
                                                    Ofte vil en behandlingsmulighed, hvor man giver kroppen, hvad den mangler af hormoner, 
                                                    kunne afhjælpe balancen og dermed symptomerne.`;
export const ostriolOverRecommendation = `Hvis dit niveau af østriol ligger over 10 pg/ml, men du alligevel oplever flere af de symptomer, som er forbundet med mangel på østriol, 
                                        så kan du med fordel øge dit niveau af østriol ved at bruge <a href="https://hormonklinikken.dk/products/moist-fugtgivende-pakke-til-torre-slimhinder-1">Moistpakken</a> i 3 mdr. Herefter kan du stoppe eller reducere behandling 
                                        til hver 2. dag.`;
export const ostriolNormalRecommendation = `Dit niveau af østriol ligger ikke optimalt, så du oplever sikkert flere af symptomerne på østriolmangel. 
                                            Derfor kunne du med fordel styrke dine slimhinder med bl.a <a href="https://hormonklinikken.dk/products/moist-fugtgivende-pakke-til-torre-slimhinder-1">Moistpakken</a>.
                                            De første 3 måneder dagligt og herefter hver 2. dag som vedligeholdelsesbehandling.`;
export const progesteronBasedOnNegativeCyklusOverRecommendation = `Dit testsvar viser, at du på trods af ophørt cyklus stadig producerer progesteron, og at du  
                                                                    ligger over den nedre værdi. 
                                                                    Har du et for højt østradiolniveau, kan det dog sagtens være, at din balance ikke er optimal, 
                                                                    og at du derfor oplever mangelsymptomer på progesteron. Årsagen er, at der ikke er progesteron 
                                                                    nok til at nedjustere østradiols funktion. 
                                                                    Hvis du oplever symptomer som fx dårlig søvn, hovedpine, hedeture, tristhed, humørsvingninger, 
                                                                    vægtøgning og væskeophobning mm (ikke nødvendigvis alle på en gang, men selv 1-2 symptomer kan være utrolig drænende), så skal du arbejde på at afbalancere din hormonbalance.
                                                                    Hvis dit østradiolniveau er før højt, så vil anbefalingen være, at du skal starte <a href="https://hormonklinikken.dk/products/detox">hormondetox</a>. Læs mere i <a href="https://hormonklinikken.dk/products/hormondetox-ebog">E-guiden</a> Hormondetox.
                                                                    Hvis du efter 1 måned ikke har mærket forbedring, og dine symptomer ikke er mindsket, så vil anbefalingen være, at du tilfører bioidentisk progesteron. 
                                                                    Hvordan du bruger det korrekt, lærer du på dette forløb: <a href="https://hormonklinikken.dk/products/forlob-til-hormonbalance">https://hormonklinikken.dk/products/forlob-til-hormonbalance</a>`;
export const progesteronBasedOnNegativeCyklusNormalRecommendation = `Dit testsvar viser, at du kunne have glæde af at tilføre bioidentisk progesteron. 
                                                                    Hvordan du bruger det korrekt, lærer du på dette forløb: <a href="https://hormonklinikken.dk/products/forlob-til-hormonbalance">https://hormonklinikken.dk/products/forlob-til-hormonbalance</a>`;
export const progesteronBasedOnPositiveCyklusOverRecommendation = `Dit testsvar viser, at du stadig producerer progesteron nok, og du ligger over den nedre værdi. 
                                                                    Har du et for højt østradiolniveau, kan det dog sagtens være, at din balance ikke er optimal, og du derfor oplever mangelsymptomer på progesteron. 
                                                                    Årsagen er, at der ikke er progesteron nok til at nedjustere østradiols funktion. 
                                                                    Hvis du oplever symptomer som fx kraftige og lange blødninger, dårlig søvn, hovedpine, hedeture, tristhed, humørsvingninger, vægtøgning og væskeophobning mm (ikke nødvendigvis alle på en gang, men selv 1-2 symptomer kan være utrolig drænende), så skal du arbejde på at afbalancere din hormonbalance.
                                                                    Hvis dit østradiolniveau er før højt, så vil anbefalingen være, at du skal starte <a href="https://hormonklinikken.dk/products/detox">hormondetox</a>. Læs mere i <a href="https://hormonklinikken.dk/products/hormondetox-ebog">E-guiden</a> Hormondetox.
                                                                    Hvis du efter 1 måned ikke har mærket forbedring, og dine symptomer ikke er mindsket, så vil anbefalingen være, at du tilfører bioidentisk progesteron. 
                                                                    Det kan dog ikke kombineres med hormonel prævention.
                                                                    Hvordan du bruger det korrekt, lærer du på dette forløb: <a href="https://hormonklinikken.dk/products/forlob-til-hormonbalance">https://hormonklinikken.dk/products/forlob-til-hormonbalance</a>`;
export const progesteronBasedOnPositiveCyklusNormalRecommendation = `Dit testsvar viser, at du kunne have glæde af at tilføre bioidentisk progesteron. 
                                                                    Hvordan du bruger det korrekt, lærer du på dette forløb: <a href="https://hormonklinikken.dk/products/forlob-til-hormonbalance">https://hormonklinikken.dk/products/forlob-til-hormonbalance</a>
                                                                    OBS! Det kan ikke kombineres med hormonel prævention som p-piller, hormonspiral eller mini-piller.`;
export const testosteronOverRecommendation = `Det er vigtigt for din styrke, udholdenhed, robusthed, muskelmasse og libido, at du også som kvinde har testosteron nok. Også på den lange bane. Hvis du, på trods af du ligger inden for normalområdet, føler dig udfordret på de områder, som testosteron varetager, så kan årsagen være den, at du måske tidligere i livet har ligget højere end gennemsnittet af kvinder. Det betyder, at du kunne profitere af at ligge noget højere, end du måske gør nu. 
                                            Progesteron er moderhormon til testosteron, og hvis du er i mangel/ubalance på det, så er det et godt sted at starte. Det tager tid, inden testosteron øges via hormonkæden, så du skal finde tålmodigheden frem. 
                                            Muskeltræning er også en måde at støtte sit testosteron på. Vær opmærksom på ikke at overtræne, hvis du er hormonelt ubalanceret. 3x30 min styrketræning om ugen er en god start.
                                            Derudover kan du styrke din krop og robusthed med bl.a. zink.`;
export const testosteronNormalRecommendation = `Det er vigtigt for din styrke, udholdenhed, robusthed, muskelmasse og libido, at du også som kvinde har testosteron nok. Også på den lange bane. 
                                                Da du ligger under optimalområdet, kan du føle dig udfordret på de områder, som testosteron varetager.
                                                Hvis du samtidig tidligere i livet har ligget højere end nu, så kan du helt sikkert også mærke det. Du kunne profitere af at ligge højere, end du gør nu. 
                                                Progesteron er moderhormon til testosteron, og hvis du er i mangel/ubalance på det, så er det et godt sted at starte. Det tager tid inden testosteron øges via hormonkæden, så du skal finde tålmodigheden frem. 
                                                Muskeltræning er også en måde at støtte sit testosteron på. Vær obs på ikke at overtræne, hvis du er hormonelt ubalanceret. 3x30 min styrketræning om ugen er en god start.
                                                Testosterontilskud skal udskrives af en gynækolog. Vær opmærksom på, at det er på dopinglisten, så det er ikke kan ikke anbefales selv at skaffe online.`;
export const testesteronOverRecommendation = `Det er vigtigt for din styrke, udholdenhed, robusthed, muskelmasse og libido, at du som mand har testosteron nok. Også på den lange bane. Hvis du, på trods af du ligger inden for normalområdet, føler dig udfordret på de områder, som testosteron varetager, så kan årsagen være den, at du måske tidligere i livet har ligget højere end gennemsnittet af mænd. Det betyder, at du kunne profitere af at ligge noget højere, end du måske gør nu. 
                                            Progesteron er moderhormon til testosteron, og hvis du er i mangel/ubalance på det, så er det et godt sted at starte. Det tager tid, inden testosteron øges via hormonkæden, så du skal finde tålmodigheden frem. 
                                            Muskeltræning er også en måde at støtte sit testosteron på. Vær opmærksom på ikke at overtræne, hvis du er hormonelt ubalanceret. 3x30 min styrketræning om ugen er en god start.
                                            OBS! Testosterontilskud er ikke noget, man selv giver sig i kast med. Det er på recept og skal udskrives af en læge. Vær opmærksom på, at det er på dopinglisten, så det kan ikke anbefales selv at skaffe online.`;
export const testesteronNormalRecommendation = `Det er vigtigt for din styrke, udholdenhed, robusthed, muskelmasse og libido, at du som mand har testosteron nok. Også på den lange bane. 
                                            Da du ligger under optimalområdet, kan du føle udfordret på de områder, som testosteron varetager.
                                            Hvis du samtidig tidligere i livet har ligget højere end nu, så kan du helt sikkert også mærke det. Du kunne profitere af at ligge højere, end du gør nu. 
                                            Progesteron er moderhormon til testosteron og hvis du er i mangel/ubalance på det, så er det et godt sted at starte. Det tager tid inden testosteron øges via hormonkæden, så du skal finde tålmodigheden frem. 
                                            Muskeltræning er også en måde at støtte sit testosteron på. Vær obs på ikke at overtræne, hvis du er hormonelt ubalanceret. 3x30 min styrketræning om ugen er en god start.
                                            OBS! Testosterontilskud er ikke noget, man selv giver sig i kast med. Det er på recept og skal udskrives af en læge. Vær opmærksom på, at det er på dopinglisten, så det er ikke kan ikke anbefales selv at skaffe online.`;
export const dheaOverRecommendation = `Et stærkt immunforsvar er alfa og omega for et godt helbred.
                                        Oplever du i perioder, at dit immunforsvar eller din energi er udfordret kan du have fokus på tilskud, der styrker disse områder. Fx D-vitamin og zink.
                                        Oplever du infektioner i bihuler, blære eller svælg kan det skyldes mangel på østriol mere end et lavt immunforsvar. Østriol sørger for, at slimhinderne ikke er permeable og dermed ikke er modtagelige for vira. Er det et tilbagevendende problem, anbefales <a href="https://hormonklinikken.dk/products/moist-fugtgivende-pakke-til-torre-slimhinder-1">Moist</a>`;
export const dheaNormalRecommendation = `Et stærkt immunforsvar er alfa og omega for et godt helbred. Oplever du i perioder, at 
dit immunforsvar eller din energi er udfordret kan du have fokus på tilskud, der styrker
disse områder. Fx D-vitamin og zink. Oplever du infektioner i bihuler, blære eller svælg
kan det skyldes mangel på østriol mere end et lavt immunforsvar. Østriol sørger for, at
slimhinderne ikke er permeable og dermed ikke er modtagelige for vira. Er det et 
tilbagevendende problem, anbefales Moistpakken fra Hormonklinikken: 
<a href="https://hormonklinikken.dk/products/moist-fugtgivende-pakke-til-torre-slimhinder-1">https://hormonklinikken.dk/products/moist-fugtgivende-pakke-til-torre-slimhinder-1</a>`;
export const cortisolOverRecommendation = `Det kan være, du selv er bekendt med årsagen til, at dit stressniveau er for højt, og anbefalingen vil være at få set på årsagerne, så dine binyrer ikke er på overarbejde.
                                            Selvfølgeligt kan det være en forbigående periode, men er det over 3 måneder, så kan det give udfordringer på den lange bane. Derfor er det vigtigt at sætte ind med at få afbalanceret hormonerne og reducere stressniveauet.
                                            Hvis du samtidig mangler progesteron anbefales bioidentisk progesteron. Progesteron er modspilleren til cortisol og helt afgørende for, at man kan reducere stress. Hvordan du bruger det korrekt, er muligt at lære på Hormonklinikkens Forløb 1, som du kan læse om her: <a href="https://hormonklinikken.dk/products/forlob-til-hormonbalance">https://hormonklinikken.dk/products/forlob-til-hormonbalance</a>`;
export const cortisolNormalRecommendation = `Det er et godt udgangspunkt, at du hverken producerer for lidt eller for meget kortisol.
                                            Oplever du alligevel en følelse af at have en nedsat stresstolerance, så kan det hænge sammen med evt. mangel på progesteron. Progesteron afbalancerer kortisol og vil derfor kunne øge stresstolerancen. Det giver robusthed. 
                                            Selvfølgelig er det vigtigt til stadighed at være opmærksom på, at dit stressniveau ikke bliver for højt.`;
export const cortisolUnderRecommendation = `Det er vigtigt at kigge ind i dit stressniveau og din livsstil for at afklare, om du kan reducere det stresskrav, der er.
                                            Det er vigtigt, at få afbalanceret dine hormoner, og hvis der er progesteronmangel få udbedret dette.
                                            Samtidig skal du støtte binyrerne; det kan være ved C-vitamin`;
export const normalResultConslusion = {
  resultNormal: `Hormonklinikkens analyse af din hormontest viser, at dine hormoner ligger inden for normalområdet. Det betyder, at din krop er i balance. En krop i hormonel balance fungerer bedre, er sundere og giver mere energi, overskud og mental stabilitet.`,
  HormontestenIntro: `viser, at nogle af dine hormoner ligger inden for normalområdet. 
    Det betyder, at din krop er helt i balance. En krop i hormonel balance fungerer bedre og giver mere energi, overskud og 
    mental stabilitet.`,
  hormonklinikkensVurdering:
    "For at opretholde din hormonelle balance vil vi anbefale følgende:",
  hormonklinikkensDetail:
    "For at opretholde din hormonelle balance vil vi anbefale følgende:",
  hormonklinikkensePoint1: `Det er altid en god ide generelt at udrense for fremmedøstrogener/hormonforstyrrende stoffer, 
    da disse hormonlignende stoffer "opfører" sig som østrogener. 
    De er ikke målt i hormontesten. Hormondetox med chlorella samt leverstøttende urter. 
    Chlorella algen er en encellet grøn alge fyldt med næring. Algen er en kraftig antioxidant og mindsker træthed og udmattelse. 
    Samtidig bidrager algen til kroppens naturlige afgiftningsproces. De leverstøttende urter gør, at udrensningen via leveren optimeres. 
    Det er også en god ide at forbygge belastningen af hormonforstyrrende stoffer i kosmetik og fødevarer. 
    Du kan læse mere om hormondetox pakken her: <a href='https://hormonklinikken.dk/products/detox<a>'>https://hormonklinikken.dk/products/detox<a>`,
  hormonklinikkensePoint2: `Hvis du vil lære mere om hvordan du hormondetoxer og forebygger, så er det muligt i e-guiden Hormondetox. 
    Du får den med rabat, hvis du køber den sammen med detoxpakken. 
    Læs mere her: <a href='https://hormonklinikken.dk/products/hormondetox-ebog<a>'>https://hormonklinikken.dk/products/hormondetox-ebog<a>`,
  hormonklinikkensePoint3: `Vi anbefaler, at du tester din hormonstatus igen om 2 år, hvis du er i en periode i livet, hvor der naturligt sker store forandringer biologisk set. 
    Får du symptomer på hormonelle ubalancer inden, anbefaler vi, at du tager en test på det tidspunkt. 
    Oplever du på nuværende tidspunkt symptomer, som du ønsker hjælp til, anbefaler vi, at du opsøger din egen læge. 
    Vi anbefaler, at du tester din hormonstatus igen om ca. 1 år, hvis du er i en periode i livet, hvor der naturligt sker store forandringer biologisk set. 
    Rigtig god fornøjelse med at fastholde din hormonbalance.`,
};
export const conclusion = {
  hormonklinikkensAnbefaling: "Hormonklinikkens anbefaling",
  resultUbalance: "KONKLUSION AF TESTRESULTAT",
  hormonklinikkensVurdering: "Hormonklinikkens analyse af din hormontest",
  UbalanceIntro: `Testsvaret fortæller, at dine hormoner ikke er i optimal balance, og du sandsynligvis oplever symptomer på denne ubalance. 
    Hormonbalancen har stor indflydelse på alle områder i kroppen og på dit generelle energiniveau. 
    Derfor kunne du med fordel hjælpe dine hormoner tilbage i balance – også selvom du ikke oplever symptomer.
    En krop i hormonel balance betyder mere energi, overskud, glæde, mentalt stabilitet og bedre sundhed!`,
  UbalanceDetail: `For at genoprette din hormonelle balance vil vi anbefale følgende:`,
  UbalancePoint1: `Hormondetox med chlorella samt leverstøttende urter. Chlorella algen er en encellet grøn alge fyldt med næring. 
    Algen er en kraftig antioxidant og mindsker træthed og udmattelse. Samtidig bidrager algen til kroppens naturlige afgiftningsproces. 
    De leverstøttende urter gør, at udrensningen via leveren optimeres. 
    Det er også en god ide at forbygge belastningen af hormonforstyrrende stoffer i kosmetik og fødevarer. 
    Du kan læse mere om hormondetox pakken her: <a href='https://hormonklinikken.dk/products/detox'>https://hormonklinikken.dk/products/detox</a>`,
  UbalancePoint2: `Det er altid en god ide generelt at udrense for fremmedøstrogener/hormonforstyrrende stoffer, 
    da disse hormonlignende stoffer "opfører" sig som østradiol. De er ikke målt i hormontesten. 
    Hvis du vil lære mere om hvordan du hormondetoxer og forebygger, så er det muligt i e-guiden Hormondetox. 
    Du får den med rabat, hvis du køber den sammen med detoxpakken. Læs mere her: <a href='https://hormonklinikken.dk/products/hormondetox-ebog'>https://hormonklinikken.dk/products/hormondetox-ebog</a>`,
  UbalancePoint3: `Vitamintilskud kan understøtte din hormondetox støtte. 
    Hormonklinikken tilbyder forskellige vitaminer og mineraler. Du kan læse mere på Hormonklinikkens side. `,
  UbalancePoint4: `Hormonklinikken hormonforløb - for at genoprette din hormonelle balance anbefaler vi Hormonklinikkens hormonforløb, 
    hvor du bringer din krop tilbage i balance. Forløbet er tilrettelagt af Hormonsygeplejersken, 
    Danmarks mest erfarne på sit felt. Hormonklinikkens hormonforløb indeholder en digital del bestående af materiale og lydfiler med enkle og 
    effektive anvisninger og råd. Du skal afsætte ca. en time om ugen til gennemgangen af dette. 
    Samtidig får du mulighed for at få svar på evt. spørgsmål ved at deltage i spørgetiden på Zoom, hvor du inden har sendt dit spørgsmål. 
    Det optages, så du kan se det efterfølgende, hvis du fx ikke har mulighed for at deltage. 
    Vi anbefaler, at du samtidig tilfører de hormoner, du mangler, som bioidentiske hormoner i form af creme. 
    Du får detaljeret vejledning i, hvordan du skal gøre bruge cremen igennem hormonforløbet. Hormonforløbet varer 12 uger. 
    Herefter du selv vil være i stand til selv at navigere i hormonerne. 
    Du kan læse mere og tilmelde dig Hormonklinikkens hormonforløb her: <a href='https://hormonklinikken.dk/products/forlob-til-hormonbalance'>https://hormonklinikken.dk/products/forlob-til-hormonbalance</a>`,
  UbalancePoint5: `Konsultation – føler du dig usikker undervejs i forløbet, anbefaler vi, 
    at du bestiller en samtale med en certificeret uddannet hormoncoach. 
    Du kan booke din samtale her: <a href='https://hormonklinikken.dk/products/konsultation-med-hormoncoach-i-forlob'>https://hormonklinikken.dk/products/konsultation-med-hormoncoach-i-forlob</a> Vi anbefaler, at du tester din hormonstatus 
    igen om ca. 1 år, hvis du er i en periode i livet, hvor der naturligt sker store forandringer biologisk set. 
    Oplever du på nuværende tidspunkt symptomer, som du har akut brug for hjælp til, så anbefaler vi, at du opsøger din egen læge. 
    Rigtig god fornøjelse med at genoprette din hormonbalance.`,
  UbalanceDetail2: `Vi anbefaler, at du tester din hormonstatus igen om ca. 1 år, hvis du er i en periode i livet, hvor der naturligt sker store 
    forandringer biologisk set. 
    Oplever du på nuværende tidspunkt symptomer, som du har akut brug for hjælp til, så anbefaler vi, at du opsøger din egen 
    læge.
    Rigtig god fornøjelse med at genoprette din hormonbalance.`,
};

export const hubSpotConclusion = `Resultat - Normal
Hormonklinikkens vurdering 
Hormontesten viser, at nogle af dine hormoner ligger inden for normalområdet. 
Det betyder, at din krop er helt i balance. En krop i hormonel balance fungerer bedre og giver mere energi, overskud og 
mental stabilitet.
Hormonklinikkens anbefaling
For at opretholde din hormonelle balance vil vi anbefale følgende:
1. Hormondetox med chorella, leverstøttende urter. Chorella algen er en encellet grøn alge fyldt med 
næring. Algen er en kraftig antioxidant og mindsker træthed og udmattelse. Samtidig bidrager algen til kroppens 
naturlige afgiftningsproces.
De leverstøttende urter gør, at udrensningen via leveren optimeres.
Det er også en god ide at forbygge belastningen af hormonforstyrrende stoffer i kosmetik og fødevarer. 
Du kan få mere at vide om hormondetox på dette link: ”Hormondetox A-Z” 
Du kan bestille detoxpakken her: ”Detoxpakke”
2. Vitamintilskud kan understøtte din hormondetox.
Hormonklinikken tilbyder forskellige tilskudspakker alt efter, hvor du er i livet. 
Du kan læse mere om pakkerne og bestille din vitaminpakke her: ”Vitaminer”
Vi anbefaler, at du tester din hormonstatus igen om 2 år, hvis du er i en periode i livet, hvor der naturligt sker store 
forandringer biologisk set. Får du symptomer på hormonelle ubalancer inden, anbefaler vi, at du tager en test på det tidspunkt.
Oplever du på nuværende tidspunkt symptomer, som du ønsker hjælp til, anbefaler vi, at du opsøger din egen læge.
Rigtig god fornøjelse med at fastholde din hormonbalance.
Resultat - Ubalance
Hormonklinikkens vurdering 
Hormontesten viser, at nogle af dine hormoner ligger uden for normalområdet. 
Det betyder, at din krop ikke er helt i balance, og at du med stor sandsynlighed oplever symptomer på denne ubalance. 
Hormonbalancen har stor indflydelse på alle områder i kroppen og på dit generelle energiniveau, så derfor kunne du med 
fordel arbejde med at genoprette din hormonbalance – også selvom du ikke har symptomer. 
En krop i hormonel balance fungerer bedre og giver mere energi, overskud og mental stabilitet.
Hormonklinikkens anbefaling
For at genoprette din hormonelle balance vil vi anbefale følgende:
1. Hormondetox med chorella, leverstøttende urter. Chorella algen er en encellet 
grøn alge fyldt med næring. Algen er en kraftig antioxidant og mindsker træthed og udmattelse. Samtidig bidrager algen
til kroppens naturlige afgiftningsproces.
De leverstøttende urter gør, at udrensningen via leveren optimeres.
Det er også en god ide at forbygge belastningen af hormonforstyrrende stoffer i kosmetik og fødevarer. 
Du kan få mere at vide om hormondetox på dette link: ”Hormondetox A-Z” 
Du kan bestille detoxpakken her: ”Detoxpakke”
2. Vitamintilskud kan understøtte din hormondetox støtte.
Hormonklinikken tilbyder forskellige tilskudspakker alt efter, hvor du er i livet. 
Du kan læse mere om pakkerne og bestille din vitaminpakke her: ”Vitaminer”
3. Hormonklinikken hormonforløb - for at genoprette din hormonelle balance anbefaler vi Hormonklinikkens hormonforløb, 
hvor du bringer din krop tilbage i balance. Forløbet er tilrettelagt af vores erfarne hormonsygeplejerske og afprøvet med
succes gennem flere år.
Hormonklinikkens hormonforløb indeholder en onlinedel bestående af lydfiler med enkle og effektive anvisninger og 
råd. Du skal afsætte ca. en time om ugen til gennemgangen af dette.  
Samtidig får du mulighed for at deltage i en ugentlig live Q & A, hvor du selv kan stille spørgsmål. Alle Q & A optages, 
så du kan se dem efterfølgende.
Vi anbefaler, at du samtidig tilfører de hormoner, du mangler, som bioidentiske hormoner i form af creme. Du får 
detaljeret vejledning i, hvordan du skal gøre bruge cremen igennem hormonforløbet.
Hormonforløbet varer 12 uger. Herefter du selv vil være i stand til selv at navigere i hormonerne.
Du kan læse mere og tilmelde dig Hormonklinikkens hormonforløb her: ”Hormonforløb”
4. Konsultation – føler du dig usikker i forløbet, anbefaler vi, at du bestiller en samtale med en certificeret uddannet 
hormoncoach.
Du kan booke din samtale her: ”Hormoncoach”
Vi anbefaler, at du tester din hormonstatus igen om ca. 1 år, hvis du er i en periode i livet, hvor der naturligt sker store 
forandringer biologisk set. 
Oplever du på nuværende tidspunkt symptomer, som du har akut brug for hjælp til, så anbefaler vi, at du opsøger din egen 
læge.
Rigtig god fornøjelse med at genoprette din hormonbalance.`;
