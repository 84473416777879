const emailTemplate = (contact) => {

  const contentFormatColumn = "font-family: 'Poppins Regular', Helvetica, Arial, sans-serif; font-size: 14px; color: #444444; line-height: 1.5; letter-spacing: 0; padding: 5px 0px;"
  const headingFormatColumn = "flex: 1; font-family: 'Aleo Bold'; font-size: 18px; color: #7A8B85; padding: 5px 0px; padding: 5px 0px;"
  const childHeadingFormatColumn = "font-family: 'Aleo Bold'; font-size: 16px; color: #7A8B85; padding: 5px 0px; padding: 5px 0px;"
  return `
<div>
  <div>
    <div style = "font-family: 'Aleo Bold'; font-size: 18px; color: #7A8B85; padding: 5px 0px;">INTRODUKTION:</div>
    <div style = "display: flex; font-family: 'Poppins Regular', Helvetica, Arial, sans-serif; font-size: 14px; color: #444444; line-height: 1.5; letter-spacing: 0;">
      <div>
        Patient Name: ${contact.firstname} ${contact.lastname}
      </div>
      <div style= "margin-left:50px">Køn: ${contact.user_gender}</div>
    </div>
  </div>
	<br>

  <div>
      <div style = "font-family: 'Poppins Regular', Helvetica, Arial, sans-serif; font-size: 14px; color: #444444; line-height: 1.5; letter-spacing: 0; padding: 5px 0px;">
        Cyklus: <span style= "margin-left:20px"> ${contact.is_cyklus} </span>
       </div>
      <div style = "font-family: 'Poppins Regular', Helvetica, Arial, sans-serif; font-size: 14px; color: #444444; line-height: 1.5; letter-spacing: 0; padding: 5px 0px;">
        Østradiol:<span style= "margin-left:20px"> ${contact.positive_cyklus}</span>
      </div>
      <div style = "font-family: 'Poppins Regular', Helvetica, Arial, sans-serif; font-size: 14px; color: #444444; line-height: 1.5; letter-spacing: 0; padding: 5px 0px;">
        Østriol: <span style= "margin-left:20px"> ${contact.ostriol_value}</span>
      </div>
      <div style = "font-family: 'Poppins Regular', Helvetica, Arial, sans-serif; font-size: 14px; color: #444444; line-height: 1.5; letter-spacing: 0; padding: 5px 0px;">
        Progesteron: <span style= "margin-left:20px"> ${contact.progesteron_value}</span>
      </div>
      <div style = "font-family: 'Poppins Regular', Helvetica, Arial, sans-serif; font-size: 14px; color: #444444; line-height: 1.5; letter-spacing: 0; padding: 5px 0px;">
        Testosteron: <span style= "margin-left:20px"> ${contact.testosteron_value}</span>
      </div>
      <div style = "font-family: 'Poppins Regular', Helvetica, Arial, sans-serif; font-size: 14px; color: #444444; line-height: 1.5; letter-spacing: 0; padding: 5px 0px;">
        DHEA: <span style= "margin-left:20px"> ${contact.dhea_value}</span>
      </div>
      <div style = "font-family: 'Poppins Regular', Helvetica, Arial, sans-serif; font-size: 14px; color: #444444; line-height: 1.5; letter-spacing: 0; padding: 5px 0px;">
        Kortisol: <span style= "margin-left:20px"> ${contact.kortisol_value}</span> 
      </div> 
  </div>
 <!-- split from here-->
  <div>
      <div style = "${headingFormatColumn}"> INTRODUKTION TIL HORMONER</div>
      <div style = "${contentFormatColumn}">${contact.hormone_knowledge}</div>
      
      <div style = "${headingFormatColumn + 'margin-top: 30px;'}">VIDEN OM ØSTROGENER</div>
      <div style = "${contentFormatColumn}">${contact.ostrogener_knowledge}</div>
      
      <div style = "${headingFormatColumn}+ 'margin-top: 30px;'">VIDEN OM ØSTRADIOL</div>
      <div style = "${contentFormatColumn}">${contact.cyklus_knowledge}</div>
      
      <div style = "${headingFormatColumn}+ 'margin-top: 30px;'">DIT TESTSVAR PÅ ØSTRADIOL</div> 
      <div style = "${contentFormatColumn}"><b>${contact.cyklus_positive_normal_response.split('.')[0]}.</b><br> ${contact.cyklus_positive_normal_response.substring(contact.cyklus_positive_normal_response.indexOf(".") + 1)}</div>
      
      <div style = "${headingFormatColumn}">ANBEFALING </div> 
      <div style = "${contentFormatColumn}">
      	${contact.cyklus_positive_normal_recommendation}
      </div>
      <!-- OSTRIOL -->
      <div style = "${headingFormatColumn+ 'margin-top: 30px;'}">VIDEN OM ØSTRIOL</div>
      <div style = "${contentFormatColumn}">${contact.ostriol_knowledge}</div>
      
      <div style = "${headingFormatColumn+ 'margin-top: 30px;'}">VIDEN OM ØSTRIOL</div>
      <div style = "${contentFormatColumn}">${contact.ostriolmangel_knowledge}</div>
      
      <div style = "${headingFormatColumn}">DIT TESTSVAR PÅ ØSTRIOL</div>
      <b style = "${contentFormatColumn}">${contact.ostriol_normal_response}</b>
      <br>
      <div style = "${headingFormatColumn}">ANBEFALING </div> 
      <div style = "${contentFormatColumn}">
      	${contact.ostriol_normal_recommendation}
      </div>
      <!--PROGESTERON -->
      
      <div style = "${headingFormatColumn+ 'margin-top: 30px;'}"> VIDEN OM PROGESTERON</div>
      <div style = "${contentFormatColumn}">${contact.progesteron_knowledge}</div>
      <div style = "padding-left: 20px;">
      	<div style = "${childHeadingFormatColumn}"> YNGRE KVINDER</div>
      	<div style = "${contentFormatColumn}">
        	Hos kvinder med menstruation og ægløsning vil produktionen af progesteron ske i store mængder i forbindelse med ægløsningen. Dvs. ca. midtvejs i anden del af cyklus. 
            Progesteron produceres i æggestokkene og frigives i forbindelse med ægløsningen. 
            Under en graviditet producerer livmoderen store mængder af progesteron, som bl.a. bruges til at opretholde graviditeten. 
            Kvinder, som tager p-piller, minipiller eller har en hormonspiral, har ikke ægløsning. Det er formålet med hormonpræparaterne. Det betyder, at de kommer til at mangle progesteron.
        </div>
        
        <div style = "${childHeadingFormatColumn}">MIDALDRENDE KVINDER</div>
      	<div style = "${contentFormatColumn}">
        	Kvinder, som fortsat har cyklus i 40-50’erne, har fra naturens hånd færre ægløsninger eller de er helt ophørt. Det betyder, at produktionen af progesteron også er ophørt, så der kan være mangel på hormonet allerede her.
            Kvinder, som tager p-piller, minipiller eller har en hormonspiral, har ikke ægløsning. Det er formålet med hormonpræparaterne. Det betyder, at de kommer til at mangle progesteron.
        </div>
        
        <div style = "${childHeadingFormatColumn}"> OVERGANGSALDER</div>
      	<div style = "${contentFormatColumn}">
        	I forbindelse med og efter overgangsalderen producerer kvinder langt mindre mængder af progesteron, da produktionen fra æggestokkene er ophørt. 
            Nu kommer produktionen primært fra binyrerne, og niveauet af progesteron er ofte for lav.
        </div>
        
        <div style = "${childHeadingFormatColumn}">MÆND</div>
      	<div style = "${contentFormatColumn}">
        	Hos mænd produceres progesteron primært i testiklerne, men produktionen falder gennem livet, hvilket betyder, 
            at der kan komme mangel på hormonet omkring mænds overgangsalder, andropause.
        </div>
        
        <div style = "${childHeadingFormatColumn}"> GENERELT OM PROGESTERON</div>
      	<div style = "${contentFormatColumn}">
        	Progesteron bliver desværre oftest kun knyttet til graviditetsproblematikker, da det ofte er det hormon, kvinder mangler, når de har svært ved at blive gravide 
            som følge af manglende ægløsning. 
            Det er dog en misforståelse, at det kun er her progesteron er relevant. Progesteron er fuldstændigt undervurderet, da det har mange andre funktioner for begge køn. 
            Først og fremmest har det helt egne funktioner og opgaver, og herudover er det også moderhormon til andre hormoner som testosteron, kortisol, DHEA.
            Derfor er det problematisk, at kvinder bruger præventionsformer, som har til formål at forhindre ægløsning.
        </div>
        <div style = "${childHeadingFormatColumn}">PROGESTERONS OPGAVER</div>
        <div style = "${contentFormatColumn}">
          <ul>
            <li>
              Progesteron er et af vores vigtigste feel-good hormoner.
            </li>
            <li>
              Progesteron fremmer søvn og godt humør.
            </li>
            <li>
              Progesteron er helt afgørende for afbalancering af stresshormonet kortisol.
            </li>
            <li>
              Hormonet virker nedregulerende og afbalancerende på østradiol, hvilket er afgørende for at modvirke østrogendominans som væskeophobning, 
              fedtophobning, kraftige blødninger mm.
            </li>
            <li>
              Progesteron øger kollagenproduktion og dermed med til at reducere rynker og appelsinhud, da hudcellernes levetid øges.
            </li>
            <li>
              Knoglestrukturen/styrken er afhængige af progesteron sammen med indtag af de rette næringsstoffer.
            </li>
          </ul>
        </div>
        
       <div style = "${childHeadingFormatColumn}">HVAD ER ÅRSAGEN TIL PROGESTERONMANGEL?</div>
      	<div style = "${contentFormatColumn}">
          Der kan være flere årsager til progesteronmangel. 
          Alder er grundlæggende en naturlig årsag, da kvinder og mænds produktion falder igennem  40’erne. Det er biologisk bestemt.
          Stress kan være en medvirkende faktor, da det ”æder” progesteron pga. øget forbrug og samtidig kan stoppe ægløsningerne hos kvinder. Præcis på samme måde som hormonelle 					præventionsformer. Har man for meget østradiol uanset køn, er kravet til progesteron 				større for at kunne afbalancere østradiol.
          De hormonforstyrrende stoffer, som er i vores miljø, virker som østradiol og kan derfor indirekte være med til et ”overforbrug” af progesteron.
          Nogle gynækologiske lidelser hos kvinder betyder også mindre progesteron. Det kan være PCO/PCOS.
        </div>
      
      <div style = "${childHeadingFormatColumn}">
      	PROGESTERONMANGEL HAR ALVORLIGE KONSEKVENSER
       </div>
      <div style = "${contentFormatColumn}">
      	 Viser en hormontest, at man har mangel på progesteron, kan man måske koble manglen sammen med nogle af de issues, man har.
        Mangel på progesteron kan hos begge køn kan give hedeture, humørsvingninger, dårlig søvn, hovedpiner og/eller migræner, nedsat stresstolerance, uro, tristhed, depression, kognitive 			problemer, ophobning af fedt og væske, vægtproblemer, øget risiko for autoimmune sygdomme og 		 knogleskørhed. Hos kvinder kan det betyde kraftige blødninger, fibromer i underlivet, cyster i 		   brysterne og øget risiko for PCO. Hos mænd kan det give prostataproblemer.
      </div>
        
     </div>
      <div style = "${headingFormatColumn}">TESTSVAR PÅ PROGESTERON</div>
      <div style = "${contentFormatColumn}">
        <b>
      	  ${contact.progesteron_based_on_positive_cyklus_normal_response}
        </b>
      </div>
      
      <div style = "${headingFormatColumn}">ANBEFALING </div> 
      <div style = "${contentFormatColumn}">
      	${contact.progesteron_based_on_positive_cyklus_normal_recommendation}
      </div>
      <!--testosteron -->
      
      <div style = "${headingFormatColumn+ 'margin-top: 30px;'}"> VIDEN OM TESTOSTERON</div>
      <div style = "${contentFormatColumn}">${contact.testosteron_knowledge}</div>
      
      <div style = "${headingFormatColumn}">DIT TESTSVAR PÅ TESTOSTERON</div>
      <b style = "${contentFormatColumn}">
      	${contact.testosteron_normal_response}
      </b>
      <br>
      <div style = "${headingFormatColumn}">ANBEFALING </div> 
      <div style = "${contentFormatColumn}">
      	${contact.testosteron_normal_recommendation}
      </div>
      
      <!-- DHEA -->
      
      <div style = "${headingFormatColumn+ 'margin-top: 30px;'}"> VIDEN OM DHEA</div>
      <div style = "${contentFormatColumn}">${contact.dhea_knowledge}</div>
      
      <div style = "${headingFormatColumn}">DIT TESTSVAR PÅ DHEA</div>
      <b style = "${contentFormatColumn}">
      	${contact.dhea_normal_response}
      </b>
      
      <div style = "${headingFormatColumn}">ANBEFALING </div> 
      <div style = "${contentFormatColumn}">
      	${contact.dhea_normal_recommendation.replace("Moist","")} <a href=" https://hormonklinikken.dk/products/moist-fugtgivende-pakke-til-torre-slimhinder-1">Moist</a> 
      </div>
      <!-- Kortisol -->
      <div style = "${headingFormatColumn+ 'margin-top: 30px;'}"> VIDEN OM KORTISOL</div>
      <div style = "${contentFormatColumn}">${contact.cortisol_knowledge}</div>
      
      <div style = "${headingFormatColumn}">DIT TESTSVAR PÅ KORTISOL</div>
      <div style = "${contentFormatColumn}">
      <b>${contact.cortisol_under_response.split(".")[0]}.</b> <br>
      	${contact.cortisol_under_response.substring(contact.cortisol_under_response.indexOf(".") + 1)}
      </div>
      
      <div style = "${headingFormatColumn}">ANBEFALING </div> 
      <div style = "${contentFormatColumn}">
      	${contact.cortisol_under_recommendation}
      </div>
      
      <!-- Conclusion-->
      
      ${contact.reportResult ? `<div style = "${headingFormatColumn}">KONKLUSION AF DIT TESTRESULTAT</div>
      <div style = "${childHeadingFormatColumn}">${contact.conclusion.resultNormal}</div>
      <div style = "${childHeadingFormatColumn}">
      	${contact.conclusion.hormonklinikkensVurdering}
      </div>      

      <div style = "${contentFormatColumn}">${contact.conclusion.hormonklinikkensDetail}</div>
      
      <div style = "${contentFormatColumn}">
        <ol>
          <li>
            ${contact.conclusion.hormonklinikkensePoint1}
          </li>
          <li>
            ${contact.conclusion.hormonklinikkensePoint2}
          </li>
          <li>
          ${contact.conclusion.hormonklinikkensePoint3}
        </li>
        </ol>
      </div>`
      :
      `<div style = "${childHeadingFormatColumn}">${contact.conclusion.resultUbalance}</div>
      <div style = "${childHeadingFormatColumn}">
      	${contact.conclusion.hormonklinikkensVurdering}
      </div>
      <div style = "${contentFormatColumn}">${contact.conclusion.UbalanceIntro}</div>
      
      <div style = "${childHeadingFormatColumn}">
      	${contact.conclusion.hormonklinikkensAnbefaling}
      </div>
      
      <div style = "${contentFormatColumn}">${contact.conclusion.UbalanceDetail}</div>
      
      <div style = "${contentFormatColumn}">
        <ol>
          <li>
            ${contact.conclusion.UbalancePoint1}
          </li>
          <li>
            ${contact.conclusion.UbalancePoint2}
          </li>
          <li>
            ${contact.conclusion.UbalancePoint3}
          </li>
          <li>
            ${contact.conclusion.UbalancePoint4}
          </li>
          <li>
          ${contact.conclusion.UbalancePoint5}
        </li>
        </ol>
     </div>
     <div style = "${contentFormatColumn}">${contact.conclusion.UbalanceDetail2}</div>`}
      
  </div>


</div>
    
    `;
};

export default emailTemplate;