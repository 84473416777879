import React from 'react'
import './App.css';
export default function FormSubmission({data}) {
  return (
    <div id="form-target" data-hs-forms-root="true">
        <div role="alert" className="hs-form__thankyou-message hs-form-2061156a-9a56-436f-9fdb-39a0fe96f3e0">
            <div>{data.inlineMessage}</div>
        </div>
    </div>
  )
}