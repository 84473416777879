import axios from "axios";
import CryptoJS from "crypto-js";
import { basUrl, xApiKey, secretKey } from "./config/config";
import emailTemplate from "./EmailTemplate.js";
const encryptApiKey = (apiKey) => {
  return CryptoJS.AES.encrypt(apiKey, secretKey).toString();
};
const sendEmail = async (params) => {
  const config = {
    headers: {
      "x-api-key": encryptApiKey(xApiKey),
      "Content-Type": "application/json",
    },
  };

  axios.defaults.baseURL = basUrl;
  const data = await axios.post('/send-email',
    {
      body: `${emailTemplate(params)}`,
      toEmail: params.email,
      is_cyklus: params.is_cyklus,
      positive_cyklus: params.positive_cyklus,
      ostriol_value: params.ostriol_value,
      testosteron_value: params.testosteron_value,
      kortisol_value: params.kortisol_value,
      dhea_value: params.dhea_value,
      progesteron_value: params.progesteron_value,
      firstname: params.firstname,
      lastname: params.lastname,
      user_gender: params.user_gender,
      cprnumber: params.cprnumber,
      collectedDate: params.collectedDate,
      receivedDate: params.receivedDate,
    },
    config
  );
};

export default sendEmail;
