import "./App.css";
import React, { useState } from "react";
import axios from "axios";
import { Formik } from "formik";
import { HubSpotEmailSchema } from "./ValidationSchema";
import FormSubmission from "./FormSubmission.js";
import env from "./config/config.js";
import sendEmail from "./api";
import {
  graphUrls,
  cyklusNormalResponse,
  cyklusOverResponse,
  cyklusPositiveOverResponse,
  cyklusPositiveNormalResponse,
  ostriolOverResponse,
  ostriolNormalResponse,
  progesteronBasedOnNegativeCyklusOverResponse,
  progesteronBasedOnNegativeCyklusNormalResponse,
  progesteronBasedOn_positiveCyklusOverResponse,
  progesteronBasedOn_positiveCyklusNormalResponse,
  testosteronOverResponse,
  testosteronNormalResponse,
  testesteronOverResponse,
  testesteronNormalResponse,
  dheaOverResponse,
  dheaNormalResponse,
  cortisolOverResponse,
  cortisolNormalResponse,
  cortisolUnderResponse,
  cyklusNormalRecommendation,
  cyklusOverRecommendation,
  cyklusPositiveOverRecommendation,
  cyklusPositiveNormalRecommendation,
  ostriolOverRecommendation,
  ostriolNormalRecommendation,
  progesteronBasedOnNegativeCyklusOverRecommendation,
  progesteronBasedOnNegativeCyklusNormalRecommendation,
  progesteronBasedOnPositiveCyklusOverRecommendation,
  progesteronBasedOnPositiveCyklusNormalRecommendation,
  testosteronOverRecommendation,
  testosteronNormalRecommendation,
  testesteronOverRecommendation,
  testesteronNormalRecommendation,
  dheaOverRecommendation,
  dheaNormalRecommendation,
  cortisolOverRecommendation,
  cortisolNormalRecommendation,
  cortisolUnderRecommendation,
  knowledgeOfOstradiol,
  knowledgeOfOstriol,
  knowledgeOfFemaleTestosteron,
  knowledgeOfMaleTestosteron,
  knowledgeOfDHEA,
  knowledgeOfCortisol,
  knowledgeOfprogesteron,
  introOnHormoner,
  conclusion,
  normalResultConslusion,
  knowledgeOfOstriolmangel,
  knowledgeOfOstrogener,
  hubSpotConclusion,
} from "./utils.js";
export default function HubspotEmail() {
  const [formSubmission, setFormSubmission] = useState(null);
  const [isloader, setIsloader] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const {
    blankImageUrl,
    recommendationHeading,
    cyklusNormalHeading,
    cortisolHeading,
    dheaHeading,
    ostriolHeading,
    progesteronHeading,
    testestorenHeadingMale,
    testosteronHeadingFemale,
    ostriolKnowledgeHeadingUrl,
    cyklusKnowledgeHeadingUrl,
    dheaKnowledgeHeadingUrl,
    testosteronKnowledgeHeadingUrl,
    progesteronKnowledgeHeadingUrl,
    cortisolKnowledgeHeadingUrl,
    hormoneKnowledgeHeadingUrl,
    ostriolmangelKnowledgeHeadingUrl,
    ostrogenerKnowledgeHeadingUrl,
  } = graphUrls;
  let cyklusPositiveNormalRecommendationHeadingGraphUrl = blankImageUrl;
  let cyklusPositiveNormalHeadingGraphUrl = blankImageUrl;
  let ostriolBelow10HeadingGraphUrl = blankImageUrl;
  let ostriolBelow10RecommendationHeadingGraphUrl = blankImageUrl;
  let progesteronBelow500HeadingGraphUrl = blankImageUrl;
  let progesteronBelow500RecommendationHeadingGraphUrl = blankImageUrl;
  let testosteronBelow20HeadingFemaleGraphUrl = blankImageUrl;
  let testosteronBelow20RecommendationHeadingGraphUrl = blankImageUrl;
  let dheaHeadingBelow300GraphUrl = blankImageUrl;
  let dheaBelow300RecommendationHeadingGraphUrl = blankImageUrl;
  let cortisolBelow1400HeadingGraphUrl = blankImageUrl;
  let cortisolBelow1400RecommendationHeadingGraphUrl = blankImageUrl;
  let cyklusPositiveNormalResponseValue = ".";
  let ostriolNormalResponseValue = ".";
  let progesteronBasedOnPositiveCyklusNormalResponseValue = ".";
  let testosteronNormalResponseValue = ".";
  let dheaNormalResponseValue = ".";
  let cortisolUnderResponseValue = ".";

  let cyklusPositiveNormalRecommendationValue = ".";
  let ostriolNormalRecommendationValue = ".";
  let progesteronBasedOnPositiveCyklusNormalRecommendationValue = ".";
  let testosteronNormalRecommendationValue = ".";
  let dheaNormalRecommendationValue = ".";
  let cortisolUnderRecommendationValue = ".";

  let cyklusKnowledgeHeading = blankImageUrl;
  let cyklusKnowledge = ".";
  let ostriolKnowledgeHeading = blankImageUrl;
  let ostriolKnowledge = ".";
  let testosteronKnowledgeHeading = blankImageUrl;
  let testosteronKnowledge = ".";
  let cortisolKnowledgeHeading = blankImageUrl;
  let cortisolKnowledge = ".";
  let dheaKnowledgeHeading = blankImageUrl;
  let dheaKnowledge = ".";
  let progesteronKnowledgeHeading = blankImageUrl;
  let progesteronKnowledge = ".";
  let ostriolmangelKnowledgeHeading = blankImageUrl;
  let ostriolmangelKnowledge = ".";

  let cyklusResult = "";
  let positiveCyklusResult = "";
  let dheaResult = "";
  let testosteronResult = "";
  let progesteronResult = "";
  let kortisolResult = "";

  const handleSubmit = async (values) => {
    let {
      cyklus,
      // cyklusPositive,
      ostriol,
      dhea,
      testosteron,
      progesteron,
      kortisol,
      user_gender,
    } = values;
    if (ostriol) {
      if (Number(ostriol) <= 9.9) {
        ostriolNormalResponseValue = ostriolNormalResponse;
        ostriolNormalRecommendationValue = ostriolNormalRecommendation;
      } else if (Number(ostriol) >= 10 && Number(ostriol) <= 20) {
        ostriolNormalResponseValue = ostriolOverResponse;
        ostriolNormalRecommendationValue = ostriolOverRecommendation;
      } else if (Number(ostriol) >= 20.1) {
        ostriolNormalResponseValue = ostriolOverResponse;
        ostriolNormalRecommendationValue = ostriolOverRecommendation;
      }
      ostriolBelow10HeadingGraphUrl = ostriolHeading;
      ostriolBelow10RecommendationHeadingGraphUrl = recommendationHeading;
      ostriolKnowledge = knowledgeOfOstriol;
      ostriolKnowledgeHeading = ostriolKnowledgeHeadingUrl;
      ostriolmangelKnowledgeHeading = ostriolmangelKnowledgeHeadingUrl;
      ostriolmangelKnowledge = knowledgeOfOstriolmangel;
    }
    let cyklusPositive = null;
    if (values.periods === "Ja") {
      cyklusPositive = cyklus;
      cyklus = null;
    }
    if (cyklus) {
      if (Number(cyklus) <= 2) {
        cyklusResult = "Normal";
        cyklusPositiveNormalResponseValue = cyklusNormalResponse;
        cyklusPositiveNormalRecommendationValue = cyklusNormalRecommendation;
      } else if (Number(cyklus) >= 2.1 && Number(cyklus) <= 2.9) {
        cyklusResult = "Mellem";
        cyklusPositiveNormalResponseValue = cyklusNormalResponse;
        cyklusPositiveNormalRecommendationValue = cyklusNormalRecommendation;
      } else if (Number(cyklus) >= 3 && Number(cyklus) <= 4) {
        cyklusResult = "Over";
        cyklusPositiveNormalResponseValue = cyklusOverResponse;
        cyklusPositiveNormalRecommendationValue = cyklusOverRecommendation;
      } else if (Number(cyklus) >= 4.1) {
        cyklusResult = "Meget over";
        cyklusPositiveNormalResponseValue = cyklusOverResponse;
        cyklusPositiveNormalRecommendationValue = cyklusOverRecommendation;
      }
      cyklusPositiveNormalRecommendationHeadingGraphUrl = recommendationHeading;
      cyklusPositiveNormalHeadingGraphUrl = cyklusNormalHeading;
      cyklusKnowledgeHeading = cyklusKnowledgeHeadingUrl;
      cyklusKnowledge = knowledgeOfOstradiol;
    } else if (cyklusPositive) {
      if (Number(cyklusPositive) <= 3) {
        positiveCyklusResult = "Under";
        cyklusPositiveNormalResponseValue = cyklusPositiveNormalResponse;
        cyklusPositiveNormalRecommendationValue =
          cyklusPositiveNormalRecommendation;
      } else if (
        Number(cyklusPositive) >= 3.1 &&
        Number(cyklusPositive) <= 4.5
      ) {
        positiveCyklusResult = "Normal";
        cyklusPositiveNormalResponseValue = cyklusPositiveNormalResponse;
        cyklusPositiveNormalRecommendationValue =
          cyklusPositiveNormalRecommendation;
      } else if (Number(cyklusPositive) >= 4.6) {
        positiveCyklusResult = "Over";
        cyklusPositiveNormalResponseValue = cyklusPositiveOverResponse;
        cyklusPositiveNormalRecommendationValue =
          cyklusPositiveOverRecommendation;
      }
      cyklusPositiveNormalRecommendationHeadingGraphUrl = recommendationHeading;
      cyklusPositiveNormalHeadingGraphUrl = cyklusNormalHeading;
      cyklusKnowledgeHeading = cyklusKnowledgeHeadingUrl;
      cyklusKnowledge = knowledgeOfOstradiol;
      cyklus = cyklusPositive;
    }
    if (user_gender == "Male") {
      if (Number(cyklus) > 2) {
        // Ubalance
        cyklusResult = "Over";
        cyklusPositiveNormalResponseValue = `Dit svar ligger for højt i forhold til den optimale værdi, som bør ligge under 2,0 pg/ml. 
        Du kan opleve symptomer på, at dit østradiol er for højt. Det kan være i form vægtøgning omkring mave og bryst samt generelt øget fedt og evt. hedeture.
        Som mand bør du ikke have et for højt østradiolniveau pga. de problematikker, det kan medføre. Det er alt fra fedtdepoter på mave, ”tævepatter”, væskeophobninger, prostataproblemer.`;
        cyklusPositiveNormalRecommendationValue = `Du skal reducere østradiol vha. en hormondetox kur. Du kan læse mere om den på dette link <a href="https://hormonklinikken.dk/products/detox">https://hormonklinikken.dk/products/detox</a>. Samtidig er det vigtigt at forebygge ny belastning. Hvordan kan du læse om i denne <a href="https://hormonklinikken.dk/products/hormondetox-ebog">E-guide</a> Hormondetox.
        Det er vigtigt, at du har fokus på kost, motion, søvn og evt. stressreducering, da det alle er faktorer, der kan støtte din krops balance.`;
      } else {
        // Normal
        cyklusResult = "Normal";
        cyklusPositiveNormalResponseValue = `Du har som mand en passende og optimal mængde af østradiol nemlig under 2 pg/ml. 
        Det er vigtigt for mænd, at de har østradiol i en passende mængde, da det har betydning for huden, skelettet, nervesystemet og de kognitive funktioner.
        Har du symptomer på væskeophobning og lettere tendens til vægtøgning/ikke kunne tabe dig, så kan du med fordel udrense; se anbefaling.`;
        cyklusPositiveNormalRecommendationValue = `Det er altid en god ide at udrense for fremmedøstrogener/hormonforstyrrende stoffer, da de ”opfører” sig som østradiol. Det måles ikke i hormontesten.
        Du kan vha. hormondetox kur støtte din hormonbalance. Du kan læse mere om den på dette link <a href="https://hormonklinikken.dk/products/detox">https://hormonklinikken.dk/products/detox</a>. Samtidig er det vigtigt at forebygge ny belastning. Hvordan kan du læse om i denne <a href="https://hormonklinikken.dk/products/hormondetox-ebog">E-guide</a> Hormondetox.`;
      }
    }
    if (dhea) {
      if (Number(dhea) <= 299) {
        dheaResult = "Under";
        dheaNormalResponseValue = dheaNormalResponse;
        dheaNormalRecommendationValue = dheaNormalRecommendation;
      } else if (Number(dhea) >= 300) {
        dheaResult = "Normalt";
        dheaNormalResponseValue = dheaOverResponse;
        dheaNormalRecommendationValue = dheaOverRecommendation;
      }
      dheaHeadingBelow300GraphUrl = dheaHeading;
      dheaBelow300RecommendationHeadingGraphUrl = recommendationHeading;
      dheaKnowledgeHeading = dheaKnowledgeHeadingUrl;
      dheaKnowledge = knowledgeOfDHEA;
    }
    if (testosteron) {
      if (user_gender === "Male") {
        if (Number(testosteron) <= 99) {
          testosteronResult = "Under";
          testosteronNormalResponseValue = testesteronNormalResponse;
          testosteronNormalRecommendationValue =
            testesteronNormalRecommendation;
        } else if (Number(testosteron) > 100) {
          testosteronResult = "Normalt";
          testosteronNormalResponseValue = testesteronOverResponse;
          testosteronNormalRecommendationValue = testesteronOverRecommendation;
        }
        testosteronBelow20HeadingFemaleGraphUrl = testestorenHeadingMale;
        testosteronBelow20RecommendationHeadingGraphUrl = recommendationHeading;
        testosteronKnowledgeHeading = testosteronKnowledgeHeadingUrl;
        testosteronKnowledge = knowledgeOfMaleTestosteron;
      } else {
        if (Number(testosteron) <= 19.9) {
          testosteronResult = "Under";
          testosteronNormalResponseValue = testosteronNormalResponse;
          testosteronNormalRecommendationValue =
            testosteronNormalRecommendation;
        } else if (Number(testosteron) >= 20 && Number(testosteron) <= 60) {
          testosteronResult = "Normalt";
          testosteronNormalResponseValue = testosteronOverResponse;
          testosteronNormalRecommendationValue = testosteronOverRecommendation;
        } else if (Number(testosteron) >= 60.1) {
          testosteronResult = "Over";
          testosteronNormalResponseValue = testosteronOverResponse;
          testosteronNormalRecommendationValue = testosteronOverRecommendation;
        }
        testosteronBelow20HeadingFemaleGraphUrl = testosteronHeadingFemale;
        testosteronBelow20RecommendationHeadingGraphUrl = recommendationHeading;
        testosteronKnowledgeHeading = testosteronKnowledgeHeadingUrl;
        testosteronKnowledge = knowledgeOfFemaleTestosteron;
      }
    }
    if ((cyklusPositive || cyklus) && progesteron) {
      if (values.periods === "Ja") {
        if (Number(progesteron) <= 500) {
          progesteronResult = "Under";
          progesteronBasedOnPositiveCyklusNormalResponseValue =
            progesteronBasedOn_positiveCyklusNormalResponse;
          progesteronBasedOnPositiveCyklusNormalRecommendationValue =
            progesteronBasedOnPositiveCyklusNormalRecommendation;
        } else if (Number(progesteron) > 500) {
          progesteronResult = "Normalt";
          progesteronBasedOnPositiveCyklusNormalResponseValue =
            progesteronBasedOn_positiveCyklusOverResponse;
          progesteronBasedOnPositiveCyklusNormalRecommendationValue =
            progesteronBasedOnPositiveCyklusOverRecommendation;
        }
        progesteronBelow500HeadingGraphUrl = progesteronHeading;
        progesteronBelow500RecommendationHeadingGraphUrl =
          recommendationHeading;
        progesteronKnowledgeHeading = progesteronKnowledgeHeadingUrl;
        progesteronKnowledge = knowledgeOfprogesteron;
      } else {
        if (Number(progesteron) <= 250) {
          progesteronResult = "Under";
          if (user_gender == "Male") {
            progesteronBasedOnPositiveCyklusNormalResponseValue =
              "Dit testsvar ligger for lavt i forhold til den optimale værdi, som bør ligge over 250 pg/ml. ";
            progesteronBasedOnPositiveCyklusNormalRecommendationValue = `Problemet med progesteronmangel er, at det oftest vil betyde at testosteronniveauet også bliver for påvirket og ofte for lavt, da det er produceret ud fra progesteron.
            Dit testsvar viser, at du kunne have glæde af at tilføre bioidentisk progesteron. 
            Hvordan du bruger det korrekt, er muligt at lære på Hormonklinikkens Forløb 1, som du kan læse om her <a href="https://hormonklinikken.dk/products/forlob-til-hormonbalance">https://hormonklinikken.dk/products/forlob-til-hormonbalance</a>`;
          } else {
            progesteronBasedOnPositiveCyklusNormalResponseValue =
              progesteronBasedOnNegativeCyklusNormalResponse;
            progesteronBasedOnPositiveCyklusNormalRecommendationValue =
              progesteronBasedOnNegativeCyklusNormalRecommendation;
          }
        } else if (Number(progesteron) > 250) {
          progesteronResult = "Normalt";
          if (user_gender == "Male") {
            progesteronBasedOnPositiveCyklusNormalResponseValue = `Dit testsvar ligger optimalt, da det ligger over 250 pg/ml.`;
            progesteronBasedOnPositiveCyklusNormalRecommendationValue = `Dit testsvar viser, at du stadig producerer progesteron nok, og du ligger over den nedre værdi. 
              Har du dog et for højt østradiolniveau, kan det sagtens være, at din balance ikke er optimal, og du derfor oplever mangelsymptomer på progesteron. 
              Årsagen er, at der ikke er progesteron nok til at nedjustere østradiols funktion. 
              Hvis du oplever symptomer som fx dårlig søvn, hovedpine, hedeture, tristhed, humørsvingninger, vægtøgning og væskeophobning mm (ikke nødvendigvis alle på en gang, men selv 1-2 symptomer kan være utrolig drænende), så skal du arbejde på at afbalancere din hormonbalance.
              Hvis dit østradiolniveau er før højt, så vil anbefalingen være, at du skal starte <a href="https://hormonklinikken.dk/products/detox">hormondetox</a>. Læs mere i <a href="https://hormonklinikken.dk/products/hormondetox-ebog">E-guiden</a> Hormondetox. 
              Hvis du efter 1 måned ikke har mærket forbedring, og dine symptomer ikke er mindsket, så vil anbefalingen være, at du evt. tilfører bioidentisk progesteron. 
              Hvordan du bruger det korrekt, er muligt at lære på Hormonklinikkens Forløb 1, som du kan læse om her <a href="https://hormonklinikken.dk/products/forlob-til-hormonbalance">https://hormonklinikken.dk/products/forlob-til-hormonbalance</a>`;
          } else {
            progesteronBasedOnPositiveCyklusNormalResponseValue =
              progesteronBasedOnNegativeCyklusOverResponse;
            progesteronBasedOnPositiveCyklusNormalRecommendationValue =
              progesteronBasedOnNegativeCyklusOverRecommendation;
          }
        }
        progesteronBelow500HeadingGraphUrl = progesteronHeading;
        progesteronBelow500RecommendationHeadingGraphUrl =
          recommendationHeading;
        progesteronKnowledgeHeading = progesteronKnowledgeHeadingUrl;
        progesteronKnowledge = knowledgeOfprogesteron;
      }
    }
    if (kortisol) {
      if (Number(kortisol) <= 1399) {
        kortisolResult = "Under";
        cortisolBelow1400HeadingGraphUrl = cortisolHeading;
        cortisolBelow1400RecommendationHeadingGraphUrl = recommendationHeading;
        cortisolUnderResponseValue = cortisolUnderResponse;
        cortisolUnderRecommendationValue = cortisolUnderRecommendation;
      } else if (Number(kortisol) >= 1400 && Number(kortisol) <= 4200) {
        kortisolResult = "Normalt";
        cortisolBelow1400HeadingGraphUrl = cortisolHeading;
        cortisolBelow1400RecommendationHeadingGraphUrl = recommendationHeading;
        cortisolUnderResponseValue = cortisolNormalResponse;
        cortisolUnderRecommendationValue = cortisolNormalRecommendation;
      } else if (Number(kortisol) >= 4201) {
        kortisolResult = "Over";
        cortisolBelow1400HeadingGraphUrl = cortisolHeading;
        cortisolBelow1400RecommendationHeadingGraphUrl = recommendationHeading;
        cortisolUnderResponseValue = cortisolOverResponse;
        cortisolUnderRecommendationValue = cortisolOverRecommendation;
      }
      cortisolKnowledgeHeading = cortisolKnowledgeHeadingUrl;
      cortisolKnowledge = knowledgeOfCortisol;
    }
    setIsloader(true);
    setDisabled(true);
    const hubspotResponse = await submitHubspotForm(values);

    values = {
      ...values,
      collectedDate: dateFormated(values.collectedDate),
      receivedDate: dateFormated(values.receivedDate),
      is_cyklus: values.periods || "Nej",
      positive_cyklus: cyklus,
      ostriol_value: ostriol,
      testosteron_value: testosteron,
      kortisol_value: kortisol,
      dhea_value: dhea,
      progesteron_value: progesteron,
      hormone_knowledge_heading: hormoneKnowledgeHeadingUrl,
      hormone_knowledge: introOnHormoner,
      ostrogener_knowledge_heading: ostrogenerKnowledgeHeadingUrl,
      ostrogener_knowledge: knowledgeOfOstrogener,
      cyklus_knowledge_heading: cyklusKnowledgeHeading,
      cyklus_knowledge: cyklusKnowledge,
      cyklus_positive_normal_heading: cyklusPositiveNormalHeadingGraphUrl,
      cyklus_positive_normal_response: cyklusPositiveNormalResponseValue,
      cyklus_positive_normal_recommendation_heading:
        cyklusPositiveNormalRecommendationHeadingGraphUrl,
      cyklus_positive_normal_recommendation:
        cyklusPositiveNormalRecommendationValue,
      ostriol_knowledge_heading: ostriolKnowledgeHeading,
      ostriol_knowledge: ostriolKnowledge,
      ostriolmangel_knowledge_heading: ostriolmangelKnowledgeHeading,
      ostriolmangel_knowledge: ostriolmangelKnowledge,
      ostriol_normal_heading: ostriolBelow10HeadingGraphUrl,
      ostriol_normal_response: ostriolNormalResponseValue,
      ostriol_normal_recommendation_heading:
        ostriolBelow10RecommendationHeadingGraphUrl,
      ostriol_normal_recommendation: ostriolNormalRecommendationValue,
      testosteron_knowledge_heading: testosteronKnowledgeHeading,
      testosteron_knowledge: testosteronKnowledge,
      testosteron_normal_heading: testosteronBelow20HeadingFemaleGraphUrl,
      testosteron_normal_response: testosteronNormalResponseValue,
      testosteron_normal_recommendation_heading:
        testosteronBelow20RecommendationHeadingGraphUrl,
      testosteron_normal_recommendation: testosteronNormalRecommendationValue,
      cortisol_knowledge_heading: cortisolKnowledgeHeading,
      cortisol_knowledge: cortisolKnowledge,
      cortisol_under_heading: cortisolBelow1400HeadingGraphUrl,
      cortisol_under_response: cortisolUnderResponseValue,
      cortisol_under_recommendation_heading:
        cortisolBelow1400RecommendationHeadingGraphUrl,
      cortisol_under_recommendation: cortisolUnderRecommendationValue,
      dhea_knowledge_heading: dheaKnowledgeHeading,
      dhea_knowledge: dheaKnowledge,
      dhea_normal_heading: dheaHeadingBelow300GraphUrl,
      dhea_normal_response: dheaNormalResponseValue,
      dhea_normal_recommendation_heading:
        dheaBelow300RecommendationHeadingGraphUrl,
      dhea_normal_recommendation: dheaNormalRecommendationValue,
      progesteron_knowledge_heading: progesteronKnowledgeHeading,
      progesteron_knowledge: progesteronKnowledge,
      progesteron_based_on_positive_cyklus_normal_heading:
        progesteronBelow500HeadingGraphUrl,
      progesteron_based_on_positive_cyklus_normal_response:
        progesteronBasedOnPositiveCyklusNormalResponseValue,
      progesteron_based_on_positive_cyklus_normal_recommendation_heading:
        progesteronBelow500RecommendationHeadingGraphUrl,
      progesteron_based_on_positive_cyklus_normal_recommendation:
        progesteronBasedOnPositiveCyklusNormalRecommendationValue,
      conclusion: isReportNormal() ? normalResultConslusion : conclusion,
      reportResult: isReportNormal(),
    };
    await sendEmail(values);
    if (hubspotResponse.status === 200) {
      setFormSubmission(hubspotResponse.data);
      setIsloader(false);
    } else {
      setFormSubmission({ inlineMessage: "Something went wrong." });
      setIsloader(false);
    }
  };
  const dateFormated = (value) => {
    const [year, month, day] = value.split("-");
    return `${day}/${month}/${year}`;
  };
  const isReportNormal = () =>
    (cyklusResult == "Normal" || positiveCyklusResult == "Normal") &&
    dheaResult == "Normalt" &&
    testosteronResult == "Normalt" &&
    progesteronResult == "Normalt" &&
    kortisolResult == "Normalt";

  const submitHubspotForm = async (values) => {
    const portalId = env.portalId;
    const formGuid = env.formGuid;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${env.formUrl}/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        fields: [
          {
            name: "firstname",
            value: values.firstname,
          },
          {
            name: "lastname",
            value: values.lastname,
          },
          {
            name: "phone",
            value: values.phone,
          },
          {
            name: "cpr_number",
            value: values.cprnumber,
          },
          {
            name: "user_gender",
            value: values.user_gender,
          },
          {
            name: "email",
            value: values.email,
          },
          {
            name: "positive_cyklus",
            value: values.cyklus,
          },
          {
            name: "ostriol_value",
            value: values.ostriol,
          },
          {
            name: "dhea_value",
            value: values.dhea,
          },
          {
            name: "testosteron_value",
            value: values.testosteron,
          },
          {
            name: "progesteron_value",
            value: values.progesteron,
          },
          {
            name: "kortisol_value",
            value: values.kortisol,
          },
          // // cyklus normal
          {
            name: "cyklus_knowledge_heading",
            value: cyklusKnowledgeHeading,
          },
          {
            name: "cyklus_knowledge",
            value: cyklusKnowledge,
          },
          // Cyklus + normal
          {
            name: "cyklus_positive_normal_heading",
            value: cyklusPositiveNormalHeadingGraphUrl,
          },
          {
            name: "cyklus_positive_normal_response",
            value: cyklusPositiveNormalResponseValue,
          },
          {
            name: "cyklus_positive_normal_recommendation_heading",
            value: cyklusPositiveNormalRecommendationHeadingGraphUrl,
          },
          {
            name: "cyklus_positive_normal_recommendation",
            value: cyklusPositiveNormalRecommendationValue,
          },
          // Ostriol below 10
          {
            name: "ostriol_knowledge_heading",
            value: ostriolKnowledgeHeading,
          },
          {
            name: "ostriol_knowledge",
            value: ostriolKnowledge,
          },
          {
            name: "ostriol_normal_heading",
            value: ostriolBelow10HeadingGraphUrl,
          },
          {
            name: "ostriol_normal_response",
            value: ostriolNormalResponseValue,
          },
          {
            name: "ostriol_normal_recommendation_heading",
            value: ostriolBelow10RecommendationHeadingGraphUrl,
          },
          {
            name: "ostriol_normal_recommendation",
            value: ostriolNormalRecommendationValue,
          },
          // progesteron based on positive cyklus normal
          {
            name: "progesteron_knowledge_heading",
            value: progesteronKnowledgeHeading,
          },
          {
            name: "progesteron_knowledge",
            value: progesteronKnowledge,
          },
          {
            name: "progesteron_based_on_positive_cyklus_normal_heading",
            value: progesteronBelow500HeadingGraphUrl,
          },
          {
            name: "progesteron_based_on_positive_cyklus_normal_response",
            value: progesteronBasedOnPositiveCyklusNormalResponseValue,
          },
          {
            name: "progesteron_based_on_positive_cyklus_normal_recommendation_heading",
            value: progesteronBelow500RecommendationHeadingGraphUrl,
          },
          {
            name: "progesteron_based_on_positive_cyklus_normal_recommendation",
            value: progesteronBasedOnPositiveCyklusNormalRecommendationValue,
          },
          // testosteron normal
          {
            name: "testosteron_knowledge_heading",
            value: testosteronKnowledgeHeading,
          },
          {
            name: "testosteron_knowledge",
            value: testosteronKnowledge,
          },
          {
            name: "testosteron_normal_heading",
            value: testosteronBelow20HeadingFemaleGraphUrl,
          },
          {
            name: "testosteron_normal_response",
            value: testosteronNormalResponseValue,
          },
          {
            name: "testosteron_normal_recommendation_heading",
            value: testosteronBelow20RecommendationHeadingGraphUrl,
          },
          {
            name: "testosteron_normal_recommendation",
            value: testosteronNormalRecommendationValue,
          },
          // dhea normal
          {
            name: "dhea_knowledge_heading",
            value: dheaKnowledgeHeading,
          },
          {
            name: "dhea_knowledge",
            value: dheaKnowledge,
          },
          {
            name: "dhea_normal_heading",
            value: dheaHeadingBelow300GraphUrl,
          },
          {
            name: "dhea_normal_response",
            value: dheaNormalResponseValue,
          },
          {
            name: "dhea_normal_recommendation_heading",
            value: dheaBelow300RecommendationHeadingGraphUrl,
          },
          {
            name: "dhea_normal_recommendation",
            value: dheaNormalRecommendationValue,
          },
          // cortisol under
          {
            name: "cortisol_knowledge_heading",
            value: cortisolKnowledgeHeading,
          },
          {
            name: "cortisol_knowledge",
            value: cortisolKnowledge,
          },
          {
            name: "cortisol_under_heading",
            value: cortisolBelow1400HeadingGraphUrl,
          },
          {
            name: "cortisol_under_response",
            value: cortisolUnderResponseValue,
          },
          {
            name: "cortisol_under_recommendation_heading",
            value: cortisolBelow1400RecommendationHeadingGraphUrl,
          },
          {
            name: "cortisol_under_recommendation",
            value: cortisolUnderRecommendationValue,
          },
          {
            name: "hormone_knowledge_heading",
            value: hormoneKnowledgeHeadingUrl,
          },
          {
            name: "hormone_knowledge",
            value: introOnHormoner,
          },
          {
            name: "ostrogener_knowledge_heading",
            value: ostrogenerKnowledgeHeadingUrl,
          },
          {
            name: "ostrogener_knowledge",
            value: knowledgeOfOstrogener,
          },
          {
            name: "ostriolmangel_knowledge_heading",
            value: ostriolmangelKnowledgeHeading,
          },
          {
            name: "ostriolmangel_knowledge",
            value: ostriolmangelKnowledge,
          },
          {
            name: "is_cyklus",
            value: values.periods || "Nej",
          },
          {
            name: "conclusion",
            value: hubSpotConclusion,
          },
        ],
      },
      config
    );
    return response;
  };

  return (
    <div className={isloader ? "background-blur" : ""}>
      {isloader && (
        <div className="divLoader">
          <svg
            className="svgLoader"
            xmlns="http://www.w3.org/2000/svg"
            width="7em"
            height="7em"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M21 12a9 9 0 11-6.219-8.56" />
          </svg>
        </div>
      )}
      <h2>Clinical Test</h2>
      <main id="main">
        <div className="container loaded">
          {formSubmission && formSubmission ? (
            <FormSubmission data={formSubmission} />
          ) : (
            <div id="form-target" data-hs-forms-root="true">
              <Formik
                initialValues={{
                  firstname: "",
                  lastname: "",
                  phone: "",
                  user_gender: "",
                  periods: "",
                  email: "",
                  cyklus: "",
                  cyklusPositive: "",
                  ostriol: "",
                  dhea: "",
                  testosteron: "",
                  progesteron: "",
                  kortisol: "",
                  cprnumber: "",
                  collectedDate: "",
                  receivedDate: "",
                }}
                validationSchema={HubSpotEmailSchema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  errors,
                  touched,
                  isValid,
                }) => (
                  <form
                    id="hs-form-2061156a-9a56-436f-9fdb-39a0fe96f3e0-ea7945df-7543-428b-94c0-bb9e2f661fda"
                    className="hs-form-2061156a-9a56-436f-9fdb-39a0fe96f3e0 hs-form hs-form_theme-round"
                    data-instance-id="ea7945df-7543-428b-94c0-bb9e2f661fda"
                    data-form-id="2061156a-9a56-436f-9fdb-39a0fe96f3e0"
                    data-portal-id="27033224"
                    lang="en"
                    data-hs-cf-bound="true"
                  >
                    <div className="hs-form__pagination-content-container">
                      <div className="hs-form__row">
                        <div className="hs-form__group">
                          <div className="hs-form__field-row">
                            <div className="hs-form__field-row__column">
                              <div className="hs-form__field hs-form__field-firstname hs-firstname">
                                <label
                                  id="firstname-label"
                                  className="hs-form__field__label"
                                  data-required="false"
                                >
                                  <span>First name</span>
                                </label>
                                <input
                                  id="firstname-input"
                                  className="hs-form__field__input"
                                  type="text"
                                  name="firstname"
                                  autoComplete="given-name"
                                  inputMode="text"
                                  placeholder="First name"
                                  value={values.firstname}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {touched.firstname && (
                                  <div className="error-message">
                                    {errors.firstname}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="hs-form__field-row__column">
                              <div className="hs-form__field hs-form__field-lastname hs-lastname">
                                <label
                                  id="lastname-label"
                                  className="hs-form__field__label"
                                  data-required="false"
                                >
                                  <span>Last name</span>
                                </label>
                                <input
                                  id="lastname-input"
                                  className="hs-form__field__input"
                                  type="text"
                                  name="lastname"
                                  autoComplete="family-name"
                                  inputMode="text"
                                  placeholder="Last name"
                                  value={values.lastname}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {touched.lastname && (
                                  <div className="error-message">
                                    {errors.lastname}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="hs-form__row">
                        <div className="hs-form__group">
                          <div className="hs-form__field-row">
                            <div className="hs-form__field-row__column">
                              <div className="hs-form__field hs-form__field-phone hs-phone">
                                <label
                                  id="phone-label"
                                  className="hs-form__field__label"
                                  data-required="false"
                                >
                                  <span>Phone number</span>
                                </label>
                                <div className="hs-form__field__phone">
                                  <input
                                    id="phone-input"
                                    className="hs-form__field__input"
                                    type="tel"
                                    name="phone"
                                    autoComplete="tel"
                                    inputMode="tel"
                                    aria-invalid="false"
                                    aria-labelledby="phone-label"
                                    aria-describedby="phone-description"
                                    aria-required="false"
                                    placeholder="Phone number"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {touched.phone && (
                                    <div className="error-message">
                                      {errors.phone}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="hs-form__field-row__column">
                              <div className="hs-form__field hs-form__field-phone hs-phone">
                                <label
                                  id="phone-label"
                                  className="hs-form__field__label"
                                  data-required="false"
                                >
                                  <span>CPR number</span>
                                </label>
                                <div className="hs-form__field__phone">
                                  <input
                                    id="phone-input"
                                    className="hs-form__field__input"
                                    type="tel"
                                    name="cprnumber"
                                    aria-invalid="false"
                                    aria-labelledby="phone-label"
                                    aria-describedby="cpr-description"
                                    aria-required="false"
                                    placeholder="CPR number"
                                    value={values.cprnumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {touched.cprnumber && (
                                    <div className="error-message">
                                      {errors.cprnumber}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="hs-form__row">
                        <div className="hs-form__group">
                          <div className="hs-form__field-row">
                            <div className="hs-form__field-row__column">
                              <div className="hs-form__field hs-form__field-firstname hs-firstname">
                                <label
                                  id="firstname-label"
                                  className="hs-form__field__label"
                                  data-required="false"
                                >
                                  <span>Collected Date</span>
                                </label>
                                <input
                                  id="firstname-input"
                                  className="hs-form__field__input"
                                  type="date"
                                  name="collectedDate"
                                  inputMode="date"
                                  placeholder="Collected date"
                                  value={values.collectedDate}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {touched.collectedDate && (
                                  <div className="error-message">
                                    {errors.collectedDate}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="hs-form__field-row__column">
                              <div className="hs-form__field hs-form__field-lastname hs-lastname">
                                <label
                                  id="lastname-label"
                                  className="hs-form__field__label"
                                  data-required="false"
                                >
                                  <span>Received Date</span>
                                </label>
                                <input
                                  id="lastname-input"
                                  className="hs-form__field__input"
                                  type="date"
                                  name="receivedDate"
                                  inputMode="date"
                                  placeholder="Received date"
                                  value={values.receivedDate}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {touched.receivedDate && (
                                  <div className="error-message">
                                    {errors.receivedDate}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="hs-form__row">
                        <div className="hs-form__group">
                          <div className="hs-form__field-row">
                            <div className="hs-form__field-row__column">
                              <div className="hs-form__field hs-form__field-user_gender hs-user_gender">
                                <label
                                  id="user_gender-label"
                                  className="hs-form__field__label"
                                  data-required="false"
                                >
                                  <span>Gender</span>
                                </label>
                                <select
                                  id="user_gender-input"
                                  className="hs-form__field__input is-placeholder"
                                  name="user_gender"
                                  value={values.user_gender}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option
                                    label="Please Select"
                                    disabled=""
                                    value=""
                                  >
                                    Please Select
                                  </option>
                                  <option label="Male" value="Han">
                                    Male
                                  </option>
                                  <option label="Female" value="Kvinde">
                                    Female
                                  </option>
                                  <option label="Other" value="Andet">
                                    Other
                                  </option>
                                </select>
                                {touched.user_gender && (
                                  <div className="error-message">
                                    {errors.user_gender}
                                  </div>
                                )}
                              </div>
                            </div>
                            {values.user_gender === "Kvinde" ? (
                              <div className="hs-form__field-row__column">
                                <div className="hs-form__field hs-form__field-user_gender hs-user_gender">
                                  <label
                                    id="periods-label"
                                    className="hs-form__field__label"
                                    data-required="false"
                                  >
                                    <span>Periods</span>
                                  </label>
                                  <select
                                    id="periods-input"
                                    className="hs-form__field__input is-placeholder"
                                    name="periods"
                                    value={values.periods}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option
                                      label="Please Select"
                                      disabled=""
                                      value=""
                                    >
                                      Please Select
                                    </option>
                                    <option label="Yes" value="Ja">
                                      Yes
                                    </option>
                                    <option label="No" value="Nej">
                                      No
                                    </option>
                                  </select>
                                  {touched.periods && (
                                    <div className="error-message">
                                      {errors.periods}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="hs-form__row">
                        <div className="hs-form__group">
                          <div className="hs-form__field-row">
                            <div className="hs-form__field-row__column">
                              <div className="hs-form__field hs-form__field-email hs-email">
                                <label
                                  id="email-label"
                                  className="hs-form__field__label hs-form__field__label--required"
                                  data-required="true"
                                >
                                  <span>Email</span>
                                </label>
                                <input
                                  id="email-input"
                                  className="hs-form__field__input"
                                  type="email"
                                  name="email"
                                  autoComplete="email"
                                  inputMode="email"
                                  value={values.email}
                                  placeholder="email address"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {touched.email && (
                                  <div className="error-message">
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="hs-form__field-row__column">
                              <div className="hs-form__field hs-form__field-cyklus hs-cyklus">
                                <label
                                  id="cyklus-label"
                                  className="hs-form__field__label hs-form__field__label--required"
                                  data-required="true"
                                >
                                  <span>Østradiol</span>
                                </label>
                                <input
                                  id="cyklus-input"
                                  className="hs-form__field__input"
                                  type="number"
                                  name="cyklus"
                                  inputMode="decimal"
                                  placeholder="Østradiol"
                                  value={values.cyklus}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {touched.cyklus && (
                                  <div className="error-message">
                                    {errors.cyklus}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="hs-form__row">
                        <div className="hs-form__group">
                          <div className="hs-form__field-row">
                            <div className="hs-form__field-row__column">
                              <div className="hs-form__field hs-form__field-ostriol hs-ostriol">
                                <label
                                  id="ostriol-label"
                                  className="hs-form__field__label hs-form__field__label--required"
                                  data-required="true"
                                >
                                  <span>Østriol</span>
                                </label>
                                <input
                                  id="ostriol-input"
                                  className="hs-form__field__input"
                                  type="number"
                                  name="ostriol"
                                  required
                                  inputMode="decimal"
                                  placeholder="Østriol"
                                  value={values.ostriol}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {touched.ostriol && (
                                  <div className="error-message">
                                    {errors.ostriol}
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* {values.cyklus || values.cyklusPositive ? */}
                            <div className="hs-form__field-row__column">
                              <div className="hs-form__field hs-form__field-progesteron hs-progesteron">
                                <label
                                  id="progesteron-label"
                                  className="hs-form__field__label hs-form__field__label--required"
                                  data-required="true"
                                >
                                  <span>Progesteron</span>
                                </label>
                                <input
                                  id="progesteron-input"
                                  className="hs-form__field__input"
                                  type="number"
                                  name="progesteron"
                                  inputMode="decimal"
                                  placeholder="Progesteron"
                                  value={values.progesteron}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {touched.progesteron && (
                                  <div className="error-message">
                                    {errors.progesteron}
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* : '' } */}
                          </div>
                        </div>
                      </div>
                      <div className="hs-form__row">
                        <div className="hs-form__group">
                          <div className="hs-form__field-row">
                            <div className="hs-form__field-row__column">
                              <div className="hs-form__field hs-form__field-testosteron hs-testosteron">
                                <label
                                  id="testosteron-label"
                                  className="hs-form__field__label hs-form__field__label--required"
                                  data-required="true"
                                >
                                  <span>Testosteron</span>
                                </label>
                                <input
                                  id="testosteron-input"
                                  className="hs-form__field__input"
                                  type="number"
                                  name="testosteron"
                                  placeholder="Testosteron"
                                  inputMode="decimal"
                                  value={values.testosteron}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {touched.testosteron && (
                                  <div className="error-message">
                                    {errors.testosteron}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="hs-form__row">
                        <div className="hs-form__group">
                          <div className="hs-form__field-row">
                            <div className="hs-form__field-row__column">
                              <div className="hs-form__field hs-form__field-dhea hs-dhea">
                                <label
                                  id="dhea-label"
                                  className="hs-form__field__label hs-form__field__label--required"
                                  data-required="true"
                                >
                                  <span>DHEA</span>
                                </label>
                                <input
                                  id="dhea-input"
                                  className="hs-form__field__input"
                                  type="number"
                                  name="dhea"
                                  placeholder="DHEA"
                                  inputMode="decimal"
                                  value={values.dhea}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {touched.dhea && (
                                  <div className="error-message">
                                    {errors.dhea}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="hs-form__field-row__column">
                              <div className="hs-form__field hs-form__field-kortisol hs-kortisol">
                                <label
                                  id="kortisol-label"
                                  className="hs-form__field__label hs-form__field__label--required"
                                  data-required="true"
                                >
                                  <span>Kortisol</span>
                                </label>
                                <input
                                  id="kortisol-input"
                                  className="hs-form__field__input"
                                  type="number"
                                  name="kortisol"
                                  required
                                  inputMode="decimal"
                                  placeholder="kortisol"
                                  value={values.kortisol}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {touched.kortisol && (
                                  <div className="error-message">
                                    {errors.kortisol}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hs-form__row">
                      <div className="hs-form__actions">
                        <button
                          type="button"
                          name="Submit"
                          className="hs-form__actions__submit"
                          onClick={handleSubmit}
                          disabled={isDisabled}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
