import * as Yup from "yup";
const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
const nameRegex = /^[a-zA-ZæøåÆØÅäöÄÖáéíóúýÁÉÍÓÚÝðÐþÞšŠžŽ]+$/

export const HubSpotEmailSchema = Yup.object().shape({
    firstname: Yup.string().matches(nameRegex, "It must be characters").required('First name is required'),
    lastname: Yup.string().matches(nameRegex, "It must be characters").required('Last name is required'),
    phone: Yup.string().matches(/^\d+$/, 'It must be numbers').required('Phone number is required'),
    user_gender: Yup.string().required('Gender is required'),
    periods: Yup.string().when('user_gender', {
        is: (user_gender) => user_gender === "Female",
        then: () => Yup.string().required('periods is required'),
        otherwise: () => Yup.string()
      }),
    email: Yup.string().matches(emailRegExp, "It must be valid email").required('Email is required'),
    cyklus: Yup.string().when('periods', {
        is: (periods) => periods === "Yes",
        then: () => Yup.string().matches(/^\d*\.?\d*$/, 'It must be numbers').required('Østradiol is required'),
        otherwise: () => Yup.string()
      }),
    ostriol: Yup.string().matches(/^\d*\.?\d*$/, 'It must be numbers').required('Ostriol is required'),
    dhea: Yup.string().matches(/^\d*\.?\d*$/, 'It must be numbers').required('DHEA is required'),
    testosteron: Yup.string().matches(/^\d*\.?\d*$/, 'It must be numbers').required('Testosteron is required'),
    progesteron: Yup.string().when('periods', {
        is: (periods) => periods != undefined,
        then: () => Yup.string().matches(/^\d*\.?\d*$/, 'It must be numbers').required('Progesteron is required'),
        otherwise: () => Yup.string()
      }),
    kortisol: Yup.string().matches(/^\d*\.?\d*$/, 'It must be numbers').required('Kortisol is required'),
    cprnumber: Yup.string().matches(/^\d{6}-?\d{4}$/, 'It must be valid').required('CPR is required'),
    collectedDate: Yup.date().required('Collected date is required'),
    receivedDate: Yup.date().required('Received date is required')
});